import React from 'react'
import closeImg from './error.png'
import './Files.scss'

const Files = ({ files, removeFile }) => {
  return (
    <div className='files'>
      {
        files.map((file, index) => {
          return (
            <div key={file.path} className='files__item'>
              { file.name }
              <img src={closeImg} alt='close'
                onClick={() => removeFile(index)}
                className='files__remove'
                width='16'
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default Files

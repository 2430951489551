import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import Files from "../Files";
import Outputs from "../Outputs";
import { strings, enums } from "../../resources";

import {
  toBase64,
  detect,
  parseText,
  uniqueFiles,
  parseSGA,
  parseBaseline
} from "./utils";
import BasicModal from "../../../App/components/SemanticModal";
import "./Ocr.scss";

const Ocr = props => {
  const [files, setFiles] = useState([]);
  const [output, setOutput] = useState({ outputs: [], totalObject: {} });
  const [isFetching, setFetching] = useState(false);

  const onDrop = useCallback(
    acceptedFiles => {
      setFiles(uniqueFiles(files.concat(acceptedFiles)));
    },
    [files]
  );

  const removeFile = useCallback(
    index => {
      files.splice(index, 1);
      const newFiles = files.slice(0);
      setFiles(newFiles);
    },
    [files]
  );

  const scan = useCallback(async () => {
    try {
      const output = [];
      let totalObject = {};
      let combinedFields = {};
      setFetching(true);
      for (let i = 0; i < files.length; i++) {
        const item = {};
        item.img = await toBase64(files[i]);
        const data = await detect(item.img);
        item.result = parseText(data.responses[0].fullTextAnnotation.text);
        combinedFields = { ...combinedFields, ...item.result };
        output.push(item);
      }
      totalObject.outputs = output;
      totalObject.fields = combinedFields;
      totalObject.formType = getTypeOfForm(totalObject.fields["FORM REF"]);
      totalObject.pNumber = getPNumber(totalObject.fields);
      setOutput(totalObject);
      setFetching(false);
    } catch (err) {
      setFetching(false);
      console.log("scan err: ", err);
    }
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="ocr">
      {isFetching && (
        <Dimmer active inverted>
          <Loader>Scanning...</Loader>
        </Dimmer>
      )}
      <section className="ocr__main">
        <div {...getRootProps()} className="ocr__dropzone">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>{strings.scanner.uploadTextDragActive}</p>
          ) : (
            <p>{strings.scanner.uploadText}</p>
          )}
        </div>
        <Files files={files} removeFile={removeFile} />
        <section className="ocr__button-group">
          <Button primary disabled={files.length ? false : true} onClick={scan}>
            {strings.scanner.scan}
          </Button>
          <Button
            disabled={output.outputs.length ? false : true}
            onClick={() => setOutput({ outputs: [], totalObject: {} })}
          >
            {strings.scanner.reset}
          </Button>
        </section>
        <Outputs
          output={output}
          setOutput={setOutput}
          hasRelatedRecord={props.hasRelatedRecord}
          submitData={props.submitData}
          parseSGA={parseSGA}
          parseBaseline={parseBaseline}
          getTypeOfForm={getTypeOfForm}
        />
        <BasicModal
          confirmMessage={strings.modal.saveDuplicateMessage}
          handleModalConfirm={props.handleModalConfirm}
          modalClassName="confirmDuplicateModal"
          modalOpen={props.confirmDuplicateModalOpen}
          showNoButton={true}
          showOkButton={false}
          showYesButton={true}
          toggleModal={props.toggleConfirmDuplicateModalVisibility}
          noLabel={strings.button.noLabel}
          yesLabel={strings.button.yesLabel}
          okLabel={strings.button.okLabel}
        />
      </section>
    </div>
  );
};

function getTypeOfForm(formType) {
  //matches in lowercase to increase change of matching
  for (let attribute in enums.formTypes) {
    if (
      enums.formTypes.hasOwnProperty(attribute) &&
      formType.toLowerCase() === (attribute + "").toLowerCase()
    ) {
      return enums.formTypes[attribute];
    }
  }
}

function getPNumber(result) {
  let pNumber;
  Object.entries(result).forEach(entry => {
    if (
      entry[0].replace(/\W/g, "").toLowerCase() ===
      strings.fieldValues.prisonernumber
    ) {
      pNumber = entry[1];
    }
  });
  return pNumber;
}
export default Ocr;

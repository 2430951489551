import { baselineActionTypes } from "../actions/baseline.actionTypes";

const initialState = {
  loadingPage: false,
  selectedBaselineData: {},
  baselineId: undefined,
  baselineToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  baselineRequestStatus: undefined,
  baselineRequestMade: false,
  isFullyLoaded: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let details;
  switch (action.type) {
    case baselineActionTypes.CREATE_BASELINE_REQUEST:
      return {
        ...state,
        baselineRequestStatus: undefined,
        baselineRequestMade: true
      };
    case baselineActionTypes.CREATE_BASELINE_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        baselineRequestStatus: true
      };
    case baselineActionTypes.CREATE_BASELINE_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        baselineRequestStatus: false
      };

    case baselineActionTypes.CLEAR_CREATE_BASELINE_REQUEST:
      return { ...state };
    case baselineActionTypes.CLEAR_CREATE_BASELINE_SUCCESS:
      return {
        ...state,
        baselineRequestMade: false,
        baselineRequestStatus: undefined
      };
    case baselineActionTypes.CLEAR_CREATE_BASELINE_FAILURE:
      return { ...state };

    case baselineActionTypes.UPDATE_BASELINE_REQUEST:
      return {
        ...state,
        loadingPage: true,
        baselineRequestStatus: undefined,
        baselineRequestMade: true
      };
    case baselineActionTypes.UPDATE_BASELINE_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        baselineRequestStatus: true,
        selectedBaselineData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case baselineActionTypes.UPDATE_BASELINE_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        baselineRequestStatus: false,
        selectedBaselineData: action.initialDetails,
        errorDetails: details
      };

    case baselineActionTypes.DELETE_BASELINE_REQUEST:
      return {
        ...state,
        baselineRequestStatus: undefined,
        baselineRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case baselineActionTypes.DELETE_BASELINE_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        baselineRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case baselineActionTypes.DELETE_BASELINE_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        baselineRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case baselineActionTypes.GET_BASELINES_BY_PROGRAMME_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case baselineActionTypes.GET_BASELINES_BY_PROGRAMME_SUCCESS:
      return {
        ...state,
        baselineId: action.response.data.Body.Item.assessmentId,
        loadingPage: false,
        isFullyLoaded: true
      };
    case baselineActionTypes.GET_BASELINE_BY_PROGRAMME_FAILURE:
      return {
        ...state,
        baselineId: undefined,
        loadingPage: false,
        isFullyLoaded: true
      };

    case baselineActionTypes.GET_BASELINE_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        baselineRequestStatus: undefined
      };
    case baselineActionTypes.GET_BASELINE_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        baselineRequestStatus: true,
        selectedBaselineData: action.response.Body.Item,
        loadingPage: false
      };
    case baselineActionTypes.GET_BASELINE_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedBaselineData: {},
        baselineRequestStatus: false,
        baselineRequestMade: true
      };

    case baselineActionTypes.UPDATE_SELECTED_BASELINE_REQUEST:
      return { ...state };
    case baselineActionTypes.UPDATE_SELECTED_BASELINE_SUCCESS:
      return {
        ...state,
        selectedBaselineData: {
          ...state.selectedBaselineData,
          [action.key]: action.value
        }
      };
    case baselineActionTypes.UPDATE_SELECTED_BASELINE_FAILURE:
      return { ...state };

    case baselineActionTypes.CLEAR_SELECTED_BASELINE_REQUEST:
      return { ...state };
    case baselineActionTypes.CLEAR_SELECTED_BASELINE_SUCCESS:
      return { ...state, selectedBaselineData: {}, isFullyLoaded: false };
    case baselineActionTypes.CLEAR_SELECTED_BASELINE_FAILURE:
      return { ...state };

    case baselineActionTypes.CANCEL_SELECTED_BASELINE_UPDATE_REQUEST:
      return { ...state };
    case baselineActionTypes.CANCEL_SELECTED_BASELINE_UPDATE_SUCCESS:
      return { ...state, selectedBaselineData: action.data };
    case baselineActionTypes.CANCEL_SELECTED_BASELINE_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

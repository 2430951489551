import AWS from "aws-sdk";
import { SystemHeaders } from "./index";
import { config, userPool, cognitoIdentity } from "../../_config/aws";
import {
  CognitoIdToken,
  CognitoRefreshToken
} from "amazon-cognito-identity-js";
import { authActions } from "../store/actions/auth.actions";
import { authService } from "../services/auth.service";
import store from "../../_store/store";

class User {
  getCognitoIdentityCredentials = () => {
    const key = `cognito-idp.${config.cognito.region}.amazonaws.com/${
      config.cognito.UserPoolId
    }`;
    let loginInfo = { [key]: null };

    const params = {
      IdentityPoolId: config.cognito.IdentityPoolId,
      Logins: loginInfo
    };

    return new AWS.CognitoIdentityCredentials(params);
  };

  // Returns whether there is a valid login session
  isLoggedIn = () => {
    let loggedIn = true;

    if (!this.checkLoginStatus()) {
      loggedIn = false;
    }

    // Refresh the current session if there's a valid login
    if (loggedIn) {
      this.refreshCredentials();
    }

    // Perform a log out if none of the criteria for a valid login have been met (DOES ATTEMPTING A REFRESH ACTUALLY HAVE ANY EFFECT ON THE LOGIN STATUS - IF NOT, WE DON'T CARE ABOUT userPool.getCurrentUser, as we have already checked it.)
    if (!loggedIn && userPool.getCurrentUser()) {
      // Show the "session has expired" message
      store.dispatch(authActions.setSessionExpired());
      this.logOut();
    }
    return loggedIn;
  };

  checkLoginStatus = () => {
    //Return true or false
    let currentTime = Math.round(new Date().getTime() / 1000);
    let cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      return false;
    }

    //There seems to be a problem with creating the headers in local storage during login
    if (!localStorage.getItem("state")) {
      // There are no headers in local storage
      //return false;
    }

    // Get the tokens without automatically refreshing the session and local storage
    const keyPrefix = `CognitoIdentityServiceProvider.${
      cognitoUser.pool.clientId
    }.${cognitoUser.username}`;
    const idTokenKey = `${keyPrefix}.idToken`;
    const idToken = new CognitoIdToken({
      IdToken: localStorage.getItem(idTokenKey)
    });

    if (!idToken) {
      return false;
    }

    // Check the expiry time on the ID token
    if (idToken.payload.exp && idToken.payload.exp <= currentTime) {
      return false;
    }

    return true;
  };

  refreshCredentials = () => {
    let loggedIn = true;
    let cognitoUser = userPool.getCurrentUser();

    if (!this.checkLoginStatus()) {
      loggedIn = false;
    }

    // Get the tokens without automatically refreshing the session and local storage
    const keyPrefix = `CognitoIdentityServiceProvider.${
      cognitoUser.pool.clientId
    }.${cognitoUser.username}`;
    const refreshTokenKey = `${keyPrefix}.refreshToken`;
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: localStorage.getItem(refreshTokenKey)
    });

    // Refresh the current session if there's a valid login
    let refreshedCredentials = null;
    if (loggedIn) {
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          console.log(err);
        } else {
          refreshedCredentials = session.getIdToken().getJwtToken();
          let currentState = SystemHeaders.load();
          // Prevent setting local storage if the session has been purged
          if (currentState["headers"]) {
            // If the authorization has been updated, push it to the authorization header
            if (
              refreshedCredentials !== currentState["headers"]["Authorization"]
            ) {
              authService.setSystemHeader(
                "Authorization",
                refreshedCredentials
              );
            }
          }

          // Refresh AWS credentials using the new refreshed session
          AWS.config.credentials = this.getCognitoIdentityCredentials();
          if (AWS.config.credentials.needsRefresh()) {
            AWS.config.credentials.refresh(err => {
              if (err) {
                console.log(err);
              }
            });
          }
        }
      });
    }
  };

  changePassword = (
    current_password,
    new_password,
    new_password_confirmation
  ) => {
    let cognitoUser = userPool.getCurrentUser();

    if (cognitoUser === null) {
      throw new Error("User not found!");
    }

    if (new_password === current_password) {
      throw new Error("Password is the same as your current password.");
    }

    if (new_password !== new_password_confirmation) {
      throw new Error("Passwords do not match!");
    }

    let accessToken = "";

    cognitoUser.getSession((err, session) => {
      if (err) {
        throw new Error(err);
      }

      accessToken = session.accessToken;

      let params = {
        AccessToken: accessToken.jwtToken,
        PreviousPassword: current_password,
        ProposedPassword: new_password
      };

      cognitoIdentity.changePassword(params, function(err, data) {
        if (err) {
          console.error(err.toString());
          return;
        }

        return data;
      });
    });
  };

  adminPasswordReset = email => {
    let params = {
      UserPoolId: config.cognito.UserPoolId,
      Username: email
    };

    cognitoIdentity.adminResetUserPassword(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
      }
    });
  };

  logOut = () => {
    // Sign the user out of the application
    try {
      let cognitoUser = userPool.getCurrentUser();
      // Only perform a formal sign out if there is a current user
      if (cognitoUser) {
        cognitoUser.signOut();
      }
    } catch (error) {
      console.log("Error signout", error);
    }
    // Clear the local storage and system headers of AWS session, tokens, and authorization
    localStorage.clear();
    SystemHeaders.purge();
  };
}

export default new User();

import React from "react";
import TableComponent from "../../components/OrganisationTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { organisationActions } from "../../store/actions/organisation.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";

const requestTimeout = 20000;

class OrganisationListContainer extends React.Component {
  state = {
    deleteModalOpen: false,
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    organisationDeletionId: "",
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.organisationRequestStatus === undefined &&
        this.props.organisationRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearOrganisationRequest();
  }

  handleDelete = () => {
    this.props.deleteOrganisation(
      this.state.organisationDeletionId,
      this.props.headers
    );
  };

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    this.props.clearOrganisationRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }

    sCrit.query = this.state.searchBarQuery;
    sCrit.fields = [
      "organisationName.normalizer",
      "phone.normalizer",
      "mainCategory.normalizer",
      "email.normalizer"
    ];
    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      sCrit.activeOnly
    );
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteOrganisationModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmDeleteModalVisibility = id => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      organisationDeletionId: id
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.organisationRequestStatus}
            requestMade={this.props.organisationRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.organisationRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.organisationRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            organisationListData={this.props.organisationListData}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </div>
    );
  };
}

OrganisationListContainer.propTypes = {
  organisationListData: PropTypes.array.isRequired,
  organisationToDelete: PropTypes.string.isRequired,
  deleteOrganisation: PropTypes.func.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getOrganisations: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { organisations, auth } = state;
  const { roleId } = auth;
  const {
    organisationListData,
    loadingPage,
    organisationToDelete,
    organisationRequestStatus,
    organisationRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = organisations;
  return {
    organisationListData,
    loadingPage,
    organisationToDelete,
    organisationRequestStatus,
    organisationRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    deleteOrganisation: (id, headers) => {
      dispatch(organisationActions.deleteOrganisation(id, headers));
    },
    clearOrganisationRequest: () => {
      dispatch(organisationActions.clearOrganisationRequest());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationListContainer);

export const customerActionTypes = {
  CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_FAILURE: "CREATE_CUSTOMER_FAILURE",

  CLEAR_CREATE_CUSTOMER_REQUEST: "CLEAR_CREATE_CUSTOMER_REQUEST",
  CLEAR_CREATE_CUSTOMER_FAILURE: "CLEAR_CREATE_CUSTOMER_FAILURE",
  CLEAR_CREATE_CUSTOMER_SUCCESS: "CLEAR_CREATE_CUSTOMER_SUCCESS",

  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",

  UPDATE_SELECTED_CUSTOMER_DATA_REQUEST:
    "UPDATE_SELECTED_CUSTOMER_DATA_REQUEST",
  UPDATE_SELECTED_CUSTOMER_DATA_SUCCESS:
    "UPDATE_SELECTED_CUSTOMER_DATA_SUCCESS",
  UPDATE_SELECTED_CUSTOMER_DATA_FAILURE:
    "UPDATE_SELECTED_CUSTOMER_DATA_FAILURE",

  UPDATE_SELECTED_CUSTOMER_REQUEST: "UPDATE_SELECTED_CUSTOMER_REQUEST",
  UPDATE_SELECTED_CUSTOMER_SUCCESS: "UPDATE_SELECTED_CUSTOMER_SUCCESS",
  UPDATE_SELECTED_CUSTOMER_FAILURE: "UPDATE_SELECTED_CUSTOMER_FAILURE",

  CLEAR_SELECTED_CUSTOMER_REQUEST: "CLEAR_SELECTED_CUSTOMER_REQUEST",
  CLEAR_SELECTED_CUSTOMER_SUCCESS: "CLEAR_SELECTED_CUSTOMER_SUCCESS",
  CLEAR_SELECTED_CUSTOMER_FAILURE: "CLEAR_SELECTED_CUSTOMER_FAILURE",

  CANCEL_SELECTED_CUSTOMER_UPDATE_REQUEST:
    "CANCEL_SELECTED_CUSTOMER_UPDATE_REQUEST",
  CANCEL_SELECTED_CUSTOMER_UPDATE_SUCCESS:
    "CANCEL_SELECTED_CUSTOMER_UPDATE_SUCCESS",
  CANCEL_SELECTED_CUSTOMER_UPDATE_FAILURE:
    "CANCEL_SELECTED_CUSTOMER_UPDATE_FAILURE",

  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_FAILURE",

  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",

  GET_CUSTOMER_BY_ID_REQUEST: "GET_CUSTOMER_BY_ID_REQUEST",
  GET_CUSTOMER_BY_ID_SUCCESS: "GET_CUSTOMER_BY_ID_SUCCESS",
  GET_CUSTOMER_BY_ID_FAILURE: "GET_CUSTOMER_BY_ID_FAILURE",

  GENERATE_CUSTOMER_ID_REQUEST: "GENERATE_CUSTOMER_ID_REQUEST",
  GENERATE_CUSTOMER_ID_SUCCESS: "GENERATE_CUSTOMER_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  GET_CUSTOMERS_BY_PNUMBER_REQUEST: "GET_CUSTOMERS_BY_PNUMBER_REQUEST",
  GET_CUSTOMERS_BY_PNUMBER_SUCCESS: "GET_CUSTOMERS_BY_PNUMBER_SUCCESS",
  GET_CUSTOMERS_BY_PNUMBER_FAILURE: "GET_CUSTOMERS_BY_PNUMBER_FAILURE"
};

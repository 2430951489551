export const outcomesList = [
  { outcomeName: "S1" },
  { outcomeName: "S2" },
  { outcomeName: "S3" },
  { outcomeName: "S4A" },
  { outcomeName: "S4B" },
  { outcomeName: "S4C" },
  { outcomeName: "S5A" },
  { outcomeName: "S5B" },
  { outcomeName: "S5C" },
  { outcomeName: "S5D" },
  { outcomeName: "S5E" },
  { outcomeName: "S5F" },
  { outcomeName: "S5G" },
  { outcomeName: "S5H" },
  { outcomeName: "S6" },
  { outcomeName: "M1" },
  { outcomeName: "M2" },
  { outcomeName: "M3" },
  { outcomeName: "M4A" },
  { outcomeName: "M4B" },
  { outcomeName: "M4C" },
  { outcomeName: "M5" },
  { outcomeName: "M6" },
  { outcomeName: "M7" },
  { outcomeName: "M8" }
];

import axios from "./axios-organisations";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createOrganisation = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error creating organisation: ", error.response.data);
          reject(error);
        });
    });
  };

  updateOrganisation = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating organisation: ", error.message);
          reject(error);
        });
    });
  };

  deleteOrganisation = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting organisation: ", error.message);
          reject(error);
        });
    });
  };

  getOrganisations = (queryString, size, from, fields, sort, activeOnly) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/list-organisations/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting organisations: ", error.message);
          reject(error);
        });
    });
  };

  getOrganisationById = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting organisation: ", error.message);
          reject(error);
        });
    });
  };
}

import { customerActionTypes } from "../actions/customer.actionTypes";

const initialState = {
  loadingPage: false,
  selectedCustomerData: {},
  customerListData: [{}],
  customerData: [{}],
  totalResults: 0,
  customerExists: 0,
  customerToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  customerRequestStatus: undefined,
  customerRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case customerActionTypes.CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        customerRequestStatus: undefined,
        customerRequestMade: true
      };
    case customerActionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        customerRequestStatus: true
      };
    case customerActionTypes.CREATE_CUSTOMER_FAILURE:
      let details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        customerRequestStatus: false
      };

    case customerActionTypes.CLEAR_CREATE_CUSTOMER_REQUEST:
      return { ...state };
    case customerActionTypes.CLEAR_CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerRequestMade: false,
        customerRequestStatus: undefined
      };
    case customerActionTypes.CLEAR_CREATE_CUSTOMER_FAILURE:
      return { ...state };

    case customerActionTypes.UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loadingPage: true,
        customerRequestStatus: undefined,
        customerRequestMade: true
      };
    case customerActionTypes.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        customerRequestStatus: true,
        selectedCustomerData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case customerActionTypes.UPDATE_CUSTOMER_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        customerRequestStatus: false,
        selectedCustomerData: action.initialDetails,
        errorDetails: details
      };

    case customerActionTypes.DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        customerRequestStatus: undefined,
        customerRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case customerActionTypes.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        customerRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case customerActionTypes.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        customerRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case customerActionTypes.GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        deleteSuccessful: false
      };
    case customerActionTypes.GET_CUSTOMERS_SUCCESS:
      let rawHits = [];
      let newTotal = 0;
      if (action.response.data.Data.records) {
        newTotal = action.response.data.Data.total;
        rawHits = action.response.data.Data.records.slice();
        rawHits.forEach((result, index) => {
          let newCustomer = Object.assign({}, result);
          rawHits[index]["_source"] = newCustomer;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        customerListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case customerActionTypes.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        customerListData: [{}],
        loadingPage: false
      };

    case customerActionTypes.GET_CUSTOMERS_BY_PNUMBER_REQUEST:
      return {
        ...state,
        loadingPage: true,
        deleteSuccessful: false
      };
    case customerActionTypes.GET_CUSTOMERS_BY_PNUMBER_SUCCESS:
      let customers = [];
      let customerExists = 0;
      if (action.response.data.Data.records) {
        customerExists = action.response.data.Data.total;
        customers = action.response.data.Data.records.slice();
        customers.forEach((result, index) => {
          let newCustomer = Object.assign({}, result);
          customers[index]["_source"] = newCustomer;
        });
      } else {
        customers = [];
      }
      return {
        ...state,
        customerData: customers,
        customerExists: customerExists,
        loadingPage: false
      };
    case customerActionTypes.GET_CUSTOMERS_BY_PNUMBER_FAILURE:
      return {
        ...state,
        customerData: [{}],
        loadingPage: false
      };

    case customerActionTypes.GET_CUSTOMER_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        customerRequestStatus: undefined
      };
    case customerActionTypes.GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        selectedCustomerData: action.response.Body,
        loadingPage: false,
        customerRequestStatus: true
      };
    case customerActionTypes.GET_CUSTOMER_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        customerRequestStatus: false,
        selectedCustomerData: {},
        customerRequestMade: true
      };

    case customerActionTypes.UPDATE_SELECTED_CUSTOMER_REQUEST:
      return { ...state };
    case customerActionTypes.UPDATE_SELECTED_CUSTOMER_SUCCESS:
      return {
        ...state,
        selectedCustomerData: {
          ...state.selectedCustomerData,
          [action.key]: action.value
        }
      };
    case customerActionTypes.UPDATE_SELECTED_CUSTOMER_FAILURE:
      return { ...state };

    case customerActionTypes.CLEAR_SELECTED_CUSTOMER_REQUEST:
      return { ...state };
    case customerActionTypes.CLEAR_SELECTED_CUSTOMER_SUCCESS:
      return { ...state, selectedCustomerData: {} };
    case customerActionTypes.CLEAR_SELECTED_CUSTOMER_FAILURE:
      return { ...state };

    case customerActionTypes.CANCEL_SELECTED_CUSTOMER_UPDATE_REQUEST:
      return { ...state };
    case customerActionTypes.CANCEL_SELECTED_CUSTOMER_UPDATE_SUCCESS:
      return { ...state, selectedCustomerData: action.data };
    case customerActionTypes.CANCEL_SELECTED_CUSTOMER_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

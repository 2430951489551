import { addressActionTypes } from "../actions/address.actionTypes";

const initialState = {
  addressSearchResults: {
    postcode: [],
    libPostcode: [],
    mailingPostcode: [],
    additionalPostcode: []
  },
  formAddressResults: {
    postcode: "",
    addressLine1: "",
    addressLine2: "",
    city: ""
  },
  addressRetrieved: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case addressActionTypes.FIND_ADDRESS_REQUEST:
      return { ...state, addressRetrieved: false };
    case addressActionTypes.FIND_ADDRESS_SUCCESS:
      let sortedAddressResults = [];
      let data = action.response.data.Data;

      data = data.sort((a, b) => {
        return a.address > b.address ? 1 : -1;
      });

      data.forEach((result, key) => {
        sortedAddressResults[key] = {
          key: key.toString(),
          value: key,
          text: result.address,
          id: result.ID,
          type: result.Type
        };
      });

      return {
        ...state,
        addressSearchResults: {
          ...state.addressSearchResults,
          [action.fieldName]: sortedAddressResults
        }
      };
    case addressActionTypes.FIND_ADDRESS_FAILURE:
      return { ...state };

    case addressActionTypes.RETRIEVE_ADDRESS_REQUEST:
      return { ...state, addressRetrieved: true };
    case addressActionTypes.RETRIEVE_ADDRESS_SUCCESS:
      return {
        ...state,
        formAddressResults: {
          postcode: action.response.postcode,
          addressLine1: action.response.line1,
          addressLine2: action.response.line2,
          city: action.response.city
        }
      };
    case addressActionTypes.RETRIEVE_ADDRESS_FAILURE:
      return { ...state, addressRetrieved: false };
    default:
      return state;
  }
};

export const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

export const yesNoSensitiveOptions = [
  { key: 1, text: "N/A", value: "N/A" },
  { key: 2, text: "Yes", value: "Yes" },
  { key: 3, text: "No", value: "No" },
  { key: 4, text: "Prefer not to say", value: "Prefer not to say" }
];

export const areaOptions = [
  { key: 1, text: "NRS", value: "NRS" },
  { key: 2, text: "NRNE", value: "NRNE" }
];

export const livingWithOptions = [
  { key: 1, text: "Parents", value: "Parents" },
  { key: 2, text: "Partner", value: "Partner" },
  { key: 3, text: "Friend/Relative", value: "Friend/Relative" },
  { key: 4, text: "Alone", value: "Alone" },
  { key: 5, text: "Homeless", value: "Homeless" }
];

export const employmentStatusOptions = [
  { key: 1, text: "Employed Full Time", value: "Employed Full Time" },
  { key: 2, text: "Employed Part Time", value: "Employed Part Time" },
  { key: 3, text: "Self Employed", value: "Self Employed" },
  {
    key: 4,
    text: "Unemployed Claiming Benefit",
    value: "Unemployed Claiming Benefit"
  },
  { key: 5, text: "Unemployed Not Claiming", value: "Unemployed Not Claiming" }
];

export const lengthOfEmploymentOptions = [
  { key: 1, text: "0-6 Months", value: "0-6 Months" },
  { key: 2, text: "7-12 Months", value: "7-12 Months" },
  { key: 3, text: "13-18 Months", value: "13-18 Months" },
  { key: 4, text: "19-24 Months", value: "19-24 Months" },
  { key: 5, text: "25-36 Months", value: "25-36 Months" },
  { key: 6, text: "36+ Months", value: "36+ Months" }
];

export const prisonSupportServiceOptions = [
  { key: 1, text: "NHS", value: "NHS" },
  { key: 2, text: "Mental Health", value: "Mental Health" },
  { key: 3, text: "Addictions", value: "Addictions" },
  { key: 4, text: "Work Party", value: "Work Party" },
  { key: 5, text: "Education", value: "Education" },
  { key: 6, text: "Citizens Advice", value: "Citizens Advice" },
  { key: 7, text: "Other", value: "Other" }
];

export const exitReasonOptions = [
  { key: 1, text: "Needs Met", value: "Needs Met" },
  { key: 2, text: "Gained Employment", value: "Gained Employment" },
  { key: 3, text: "Dismissed/Unsuitable", value: "Dismissed/Unsuitable" },
  {
    key: 4,
    text: "Medical (unable to participate)",
    value: "Medical (unable to participate)"
  },
  { key: 5, text: "Moved Away", value: "Moved Away" },
  { key: 6, text: "No Contact", value: "No Contact" },
  {
    key: 7,
    text: "Own Accord/Refused Service",
    value: "Own Accord/Refused Service"
  },
  {
    key: 8,
    text: "Returned to Prison (Offence prior to mentoring service)",
    value: "Returned to Prison (Offence prior to mentoring service)"
  },
  {
    key: 9,
    text: "Returned to Prison (Offence since mentoring service)",
    value: "Returned to Prison (Offence since mentoring service)"
  },
  {
    key: 10,
    text: "Returned to Prison (unknown charge)",
    value: "Returned to Prison (unknown charge)"
  },
  { key: 11, text: "Drug/Alcohol Relapse", value: "Drug/Alcohol Relapse" },
  { key: 12, text: "Deceased", value: "Deceased" },
  { key: 13, text: "Other", value: "Other" }
];

export const typeOfExitOptions = [
  { key: 1, text: "Planned", value: "Planned" },
  { key: 2, text: "Unplanned", value: "Unplanned" }
];

export const prisonOrganisationOptions = [
  { key: 1, text: "Wise Group", value: "Wise Group" },
  { key: 2, text: "SACRO", value: "SACRO" },
  { key: 3, text: "APEX", value: "APEX" },
  { key: 4, text: "Turning Point Scotland", value: "Turning Point Scotland" }
];

export const communityOrganisationOptions = [
  { key: 1, text: "Wise Group", value: "Wise Group" },
  { key: 2, text: "SACRO", value: "SACRO" },
  { key: 3, text: "APEX", value: "APEX" },
  { key: 4, text: "Turning Point Scotland", value: "Turning Point Scotland" },
  { key: 5, text: "Circle", value: "Circle" },
  { key: 6, text: "YCSA", value: "YCSA" }
];

export const journeyStatusOptions = [
  {
    key: 1,
    text: "On Programme - In Prison",
    value: "On Programme - In Prison"
  },
  {
    key: 2,
    text: "On Programme - In Community",
    value: "On Programme - In Community"
  },
  {
    key: 3,
    text: "On Programme - Disengaged",
    value: "On Programme - Disengaged"
  },
  { key: 4, text: "Exit", value: "Exit" }
];

export const whoMetAtGateOptions = [
  { key: 1, text: "Parent/Guardian", value: "Parent/Guardian" },
  { key: 2, text: "Friend/Relative", value: "Friend/Relative" },
  { key: 3, text: "Other", value: "Other" }
];

export const programmeEngagementOptions = [
  { key: 1, text: "Pre-release Prison", value: "Pre-release Prison" },
  { key: 2, text: "Mid-point Prison", value: "Mid-point Prison" },
  { key: 3, text: "Gate Liberation", value: "Gate Liberation" },
  { key: 4, text: "Weekly", value: "Weekly" },
  { key: 5, text: "Monthly", value: "Monthly" },
  { key: 6, text: "Exit", value: "Exit" },
  { key: 7, text: "3rd Party", value: "3rd Party" }
];

import { activityActionTypes } from "../actions/activity.actionTypes";

const initialState = {
  loadingPage: false,
  selectedActivityData: {},
  activityListData: [{}],
  totalResults: 0,
  activityToDelete: "",
  result: "",
  error: "",
  errorDetails: [],
  activityRequestStatus: undefined,
  activityRequestMade: false,
  deleteSuccessful: false,
  showNotification: true,
  typeCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case activityActionTypes.CREATE_ACTIVITY_REQUEST:
      return {
        ...state,
        activityRequestStatus: undefined,
        activityRequestMade: true
      };
    case activityActionTypes.CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        activityRequestStatus: true
      };
    case activityActionTypes.CREATE_ACTIVITY_FAILURE:
      let detailsArray = [];
      if (action.error.response.data.Details) {
        let newErrorDetails = action.error.response.data.Details;
        Object.keys(newErrorDetails).forEach(key => {
          let value = newErrorDetails[key];
          detailsArray = [...detailsArray, ...value];
        });
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: detailsArray,
        activityRequestStatus: false
      };

    case activityActionTypes.CLEAR_CREATE_ACTIVITY_REQUEST:
      return { ...state };
    case activityActionTypes.CLEAR_CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityRequestMade: false,
        activityRequestStatus: undefined
      };
    case activityActionTypes.CLEAR_CREATE_ACTIVITY_FAILURE:
      return { ...state };

    case activityActionTypes.UPDATE_ACTIVITY_REQUEST:
      return {
        ...state,
        loadingPage: true,
        activityRequestStatus: undefined,
        activityRequestMade: true
      };
    case activityActionTypes.UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        activityRequestStatus: true,
        selectedActivityData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case activityActionTypes.UPDATE_ACTIVITY_FAILURE:
      detailsArray = [];
      if (action.error.response.data.Details) {
        let newErrorDetails = action.error.response.data.Details;
        Object.keys(newErrorDetails).forEach(key => {
          let value = newErrorDetails[key];
          detailsArray = [...detailsArray, ...value];
        });
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        activityRequestStatus: false,
        selectedActivityData: action.initialDetails,
        errorDetails: detailsArray
      };

    case activityActionTypes.DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        activityRequestStatus: undefined,
        activityRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case activityActionTypes.DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        activityRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case activityActionTypes.DELETE_ACTIVITY_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        activityRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case activityActionTypes.GET_ACTIVITIES_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case activityActionTypes.GET_ACTIVITIES_SUCCESS:
      let rawHits = [];
      let newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newActivity = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newActivity;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        activityListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case activityActionTypes.GET_ACTIVITIES_FAILURE:
      return { ...state, activityListData: [{}], loadingPage: false };

    case activityActionTypes.GET_ACTIVITIES_BY_ENGAGEMENT_REQUEST:
      return { ...state, loadingPage: true };
    case activityActionTypes.GET_ACTIVITIES_BY_ENGAGEMENT_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.activities) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.activities.slice();
        rawHits.forEach((result, index) => {
          let newActivity = Object.assign({}, result);
          rawHits[index]["_source"] = newActivity;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        activityListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case activityActionTypes.GET_ACTIVITIES_BY_ENGAGEMENT_FAILURE:
      return { ...state, activityListData: [{}], loadingPage: false };

    case activityActionTypes.GET_ACTIVITY_BY_ID_REQUEST:
      return { ...state, loadingPage: true, activityRequestStatus: undefined };
    case activityActionTypes.GET_ACTIVITY_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        activityRequestStatus: true,
        selectedActivityData: action.response.Body.Item,
        loadingPage: false
      };
    case activityActionTypes.GET_ACTIVITY_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedActivityData: {},
        activityRequestStatus: false,
        activityRequestMade: true
      };

    case activityActionTypes.UPDATE_SELECTED_ACTIVITY_REQUEST:
      return { ...state };
    case activityActionTypes.UPDATE_SELECTED_ACTIVITY_SUCCESS:
      return {
        ...state,
        selectedActivityData: {
          ...state.selectedActivityData,
          [action.key]: action.value
        }
      };
    case activityActionTypes.UPDATE_SELECTED_ACTIVITY_FAILURE:
      return { ...state };

    case activityActionTypes.CLEAR_SELECTED_ACTIVITY_REQUEST:
      return { ...state };
    case activityActionTypes.CLEAR_SELECTED_ACTIVITY_SUCCESS:
      return { ...state, selectedActivityData: {} };
    case activityActionTypes.CLEAR_SELECTED_ACTIVITY_FAILURE:
      return { ...state };

    case activityActionTypes.CANCEL_SELECTED_ACTIVITY_UPDATE_REQUEST:
      return { ...state };
    case activityActionTypes.CANCEL_SELECTED_ACTIVITY_UPDATE_SUCCESS:
      return { ...state, selectedActivityData: action.data };
    case activityActionTypes.CANCEL_SELECTED_ACTIVITY_UPDATE_FAILURE:
      return { ...state };

    case activityActionTypes.GET_ACTIVITY_TYPE_COUNT_REQUEST:
      return { ...state };
    case activityActionTypes.GET_ACTIVITY_TYPE_COUNT_SUCCESS:
      return { ...state, typeCount: action.response.data.Count };
    case activityActionTypes.GET_ACTIVITY_TYPE_COUNT_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings, formTypeOptions } from "../../resources";
import { ValidatedFormDropdown } from "../validation";
import "../css/ReferralComponents.scss";

class ChooseForm extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.formType}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.formType}
              dropdownLabel={strings.form.label.formType}
              dropdownName="formType"
              dropdownOptions={
                this.props.selectedReferralData.referralMeetingDate
                  ? formTypeOptions
                  : formTypeOptions.filter(
                      option => option.value === strings.fieldValues.referral
                    )
              }
              dropdownPlaceholder={strings.form.placeholder.formType}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.formType}
              required={false}
            />
          </Form.Group>
        </div>
      </div>
    );
  };
}

ChooseForm.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default ChooseForm;

import React from "react";
import { ValidatedFormDropdown } from "./validation";
import PropTypes from "prop-types";
import "../App.scss";

class AddressInput extends React.Component {
  render = () => {
    const {
      formInvalid,
      dropdownLabel,
      dropdownName,
      dropdownOptions,
      dropdownPlaceholder,
      handlePostcodeChanged,
      validationResult,
      handlePostcodeClicked,
      formFieldWidth,
      open,
      disabled
    } = this.props;
    return (
      <ValidatedFormDropdown
        formInvalid={formInvalid}
        inputData={undefined}
        dropdownLabel={dropdownLabel}
        dropdownName={dropdownName}
        dropdownOptions={dropdownOptions}
        dropdownPlaceholder={dropdownPlaceholder}
        formFieldWidth={formFieldWidth}
        onSearchChange={(e, data) =>
          handlePostcodeChanged(e, data, dropdownName)
        }
        handleChange={(e, value) => handlePostcodeClicked(e, value)}
        validationResult={validationResult}
        required={false}
        open={open}
        noResultsMessage={"No results found, please enter a valid postcode."}
        dropdownDisabled={disabled}
      />
    );
  };
}

AddressInput.propTypes = {
  formInvalid: PropTypes.bool,
  inputData: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dropdownLabel: PropTypes.string.isRequired,
  dropdownName: PropTypes.string.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  dropdownPlaceholder: PropTypes.string.isRequired,
  formFieldWidth: PropTypes.number.isRequired,
  validationResult: PropTypes.array,
  handlePostcodeChanged: PropTypes.func.isRequired,
  handlePostcodeClicked: PropTypes.func.isRequired,
  addressRetrieved: PropTypes.bool,
  open: PropTypes.bool,
  disabled: PropTypes.bool
};

export default AddressInput;

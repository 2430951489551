import { userActionTypes } from "../actions/user.actionTypes";

const initialState = {
  loadingPage: false,
  selectedUserData: {},
  prisonMentor: {},
  userListData: [{}],
  totalResults: 0,
  userToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  userRequestStatus: undefined,
  userRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.CREATE_USER_REQUEST:
      return { ...state, userRequestStatus: undefined, userRequestMade: true };
    case userActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        userRequestStatus: true
      };
    case userActionTypes.CREATE_USER_FAILURE:
      let details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        userRequestStatus: false
      };

    case userActionTypes.CLEAR_CREATE_USER_REQUEST:
      return { ...state };
    case userActionTypes.CLEAR_CREATE_USER_SUCCESS:
      return { ...state, userRequestMade: false, userRequestStatus: undefined };
    case userActionTypes.CLEAR_CREATE_USER_FAILURE:
      return { ...state };

    case userActionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        loadingPage: true,
        userRequestStatus: undefined,
        userRequestMade: true
      };
    case userActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        userRequestStatus: true,
        selectedUserData: action.response.data.Data.Item,
        showNotification: action.showNotification
      };
    case userActionTypes.UPDATE_USER_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        userRequestStatus: false,
        selectedUserData: action.initialDetails,
        errorDetails: details
      };

    case userActionTypes.DELETE_USER_REQUEST:
      return {
        ...state,
        userRequestStatus: undefined,
        userRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case userActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        userRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case userActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        userRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case userActionTypes.GET_USERS_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case userActionTypes.GET_USERS_SUCCESS:
      let rawHits = [];
      let newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newUser = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newUser;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        userListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case userActionTypes.GET_USERS_FAILURE:
      return { ...state, userListData: [{}], loadingPage: false };

    case userActionTypes.GET_USER_BY_ID_REQUEST:
      return { ...state, loadingPage: true, userRequestStatus: undefined };
    case userActionTypes.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        userRequestStatus: true,
        selectedUserData: action.response.Data,
        loadingPage: false
      };
    case userActionTypes.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedUserData: {},
        userRequestStatus: false,
        userRequestMade: true
      };

    case userActionTypes.GET_PRISONMENTOR_BY_ID_REQUEST:
      return { ...state, loadingPage: true, userRequestStatus: undefined };
    case userActionTypes.GET_PRISONMENTOR_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        userRequestStatus: true,
        prisonMentor: action.response.Data,
        loadingPage: false
      };
    case userActionTypes.GET_PRISONMENTOR_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        prisonMentor: {},
        userRequestStatus: false,
        userRequestMade: true
      };

    case userActionTypes.UPDATE_SELECTED_USER_REQUEST:
      return { ...state };
    case userActionTypes.UPDATE_SELECTED_USER_SUCCESS:
      return {
        ...state,
        selectedUserData: {
          ...state.selectedUserData,
          [action.key]: action.value
        }
      };
    case userActionTypes.UPDATE_SELECTED_USER_FAILURE:
      return { ...state };

    case userActionTypes.CLEAR_SELECTED_USER_REQUEST:
      return { ...state };
    case userActionTypes.CLEAR_SELECTED_USER_SUCCESS:
      return { ...state, selectedUserData: {} };
    case userActionTypes.CLEAR_SELECTED_USER_FAILURE:
      return { ...state };

    case userActionTypes.CLEAR_SELECTED_PRISONMENTOR_REQUEST:
      return { ...state };
    case userActionTypes.CLEAR_SELECTED_PRISONMENTOR_SUCCESS:
      return { ...state, prisonMentor: {} };
    case userActionTypes.CLEAR_SELECTED_PRISONMENTOR_FAILURE:
      return { ...state };

    case userActionTypes.CANCEL_SELECTED_USER_UPDATE_REQUEST:
      return { ...state };
    case userActionTypes.CANCEL_SELECTED_USER_UPDATE_SUCCESS:
      return { ...state, selectedUserData: action.data };
    case userActionTypes.CANCEL_SELECTED_USER_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

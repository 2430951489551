import React from "react";
import PropTypes from "prop-types";

class ShowIfSameArea extends React.Component {
  render = () => {
    return (
      <>
        {this.props.allowedArea === this.props.area &&
          this.props.children}
      </>
    );
  };
}

ShowIfSameArea.propTypes = {
  allowedArea: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};


export default ShowIfSameArea;

import React from "react";
import { Form, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import DisplayValidatedInfo from "./DisplayValidatedInfo";

const ValidatedFormInput = props => {
  return (props.mode && props.mode === "view") || props.disabled ? (
    <DisplayValidatedInfo
      className="formInputs"
      inputData={props.inputData}
      inputLabel={props.inputLabel}
      formFieldWidth={props.formFieldWidth}
      popUp={props.popUp}
      required={props.required === false ? false : true}
      noDefaultText={props.noDefaultText}
    />
  ) : (
    <Form.Field width={props.formFieldWidth}>
      <Form.Input
        className="formInputs"
        label={
          <label>
            {`${props.inputLabel} `}
            {props.popUp}
          </label>
        }
        name={props.inputName}
        type={props.inputType}
        onChange={props.handleChange}
        placeholder={props.inputPlaceholder}
        disabled={props.disabled || false}
        required={props.required === false ? false : true}
        value={props.inputData || ""}
        onKeyPress={props.onKeyPress}
        iconPosition={props.iconPosition}
        icon={props.icon}
        maxLength={props.maxLength}
        tabIndex={props.tabIndex}
      />
      <Message
        className={props.inputClassName}
        attached="bottom"
        error
        hidden={props.hideMessage}
        visible={props.formInvalid && (props.validationResult ? true : false)}
        content={
          props.validationResult ? props.validationResult.toString() : ""
        }
      />
    </Form.Field>
  );
};

ValidatedFormInput.propTypes = {
  formInvalid: PropTypes.bool,
  inputData: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  formFieldWidth: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onKeyPress: PropTypes.func,
  validationResult: PropTypes.array,
  mode: PropTypes.string
};

export default React.memo(ValidatedFormInput);

import { assessmentSGActionTypes } from "../actions/assessmentSG.actionTypes";

const initialState = {
  loadingPage: false,
  selectedAssessmentSGData: {},
  assessmentSGListData: [{}],
  totalResults: 0,
  assessmentSGToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  assessmentSGRequestStatus: undefined,
  assessmentSGRequestMade: false,
  assessmentId: undefined,
  isFullyLoaded: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case assessmentSGActionTypes.CREATE_ASSESSMENTSG_REQUEST:
      return {
        ...state,
        assessmentSGRequestStatus: undefined,
        assessmentSGRequestMade: true
      };
    case assessmentSGActionTypes.CREATE_ASSESSMENTSG_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        assessmentSGRequestStatus: true
      };
    case assessmentSGActionTypes.CREATE_ASSESSMENTSG_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        assessmentSGRequestStatus: false
      };

    case assessmentSGActionTypes.CLEAR_CREATE_ASSESSMENTSG_REQUEST:
      return { ...state };
    case assessmentSGActionTypes.CLEAR_CREATE_ASSESSMENTSG_SUCCESS:
      return {
        ...state,
        assessmentSGRequestMade: false,
        assessmentSGRequestStatus: undefined
      };
    case assessmentSGActionTypes.CLEAR_CREATE_ASSESSMENTSG_FAILURE:
      return { ...state };

    case assessmentSGActionTypes.UPDATE_ASSESSMENTSG_REQUEST:
      return {
        ...state,
        loadingPage: true,
        assessmentSGRequestStatus: undefined,
        assessmentSGRequestMade: true
      };
    case assessmentSGActionTypes.UPDATE_ASSESSMENTSG_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        assessmentSGRequestStatus: true,
        selectedAssessmentSGData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case assessmentSGActionTypes.UPDATE_ASSESSMENTSG_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        assessmentSGRequestStatus: false,
        selectedAssessmentSGData: action.initialDetails,
        errorDetails: details
      };

    case assessmentSGActionTypes.DELETE_ASSESSMENTSG_REQUEST:
      return {
        ...state,
        assessmentSGRequestStatus: undefined,
        assessmentSGRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case assessmentSGActionTypes.DELETE_ASSESSMENTSG_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        assessmentSGRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case assessmentSGActionTypes.DELETE_ASSESSMENTSG_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        assessmentSGRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case assessmentSGActionTypes.GET_ASSESSMENTSG_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case assessmentSGActionTypes.GET_ASSESSMENTSG_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newAssessmentSG = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newAssessmentSG;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        assessmentSGListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case assessmentSGActionTypes.GET_ASSESSMENTSG_FAILURE:
      return { ...state, assessmentSGListData: [{}], loadingPage: false };

    case assessmentSGActionTypes.GET_ASSESSMENTSG_BY_PROGRAMME_REQUEST:
      return { ...state, loadingPage: true };
    case assessmentSGActionTypes.GET_ASSESSMENTSG_BY_PROGRAMME_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.assessmentSG) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.assessmentSG.slice();
        rawHits.forEach((result, index) => {
          let newAssessmentSG = Object.assign({}, result);
          rawHits[index]["_source"] = newAssessmentSG;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        assessmentSGListData: rawHits,
        totalResults: newTotal,
        loadingPage: false,
        assessmentId: action.response.data.Body.Item.assessmentId,
        isFullyLoaded: true
      };
    case assessmentSGActionTypes.GET_ASSESSMENTSG_BY_PROGRAMME_FAILURE:
      return {
        ...state,
        assessmentId: undefined,
        assessmentSGListData: [{}],
        loadingPage: false,
        isFullyLoaded: false
      };

    case assessmentSGActionTypes.GET_ASSESSMENTSG_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        assessmentSGRequestStatus: undefined
      };
    case assessmentSGActionTypes.GET_ASSESSMENTSG_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        assessmentSGRequestStatus: true,
        selectedAssessmentSGData: action.response.Body.Item,
        loadingPage: false
      };
    case assessmentSGActionTypes.GET_ASSESSMENTSG_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedAssessmentSGData: {},
        assessmentSGRequestStatus: false,
        assessmentSGRequestMade: true
      };

    case assessmentSGActionTypes.UPDATE_SELECTED_ASSESSMENTSG_REQUEST:
      return { ...state };
    case assessmentSGActionTypes.UPDATE_SELECTED_ASSESSMENTSG_SUCCESS:
      return {
        ...state,
        selectedAssessmentSGData: {
          ...state.selectedAssessmentSGData,
          [action.key]: action.value
        }
      };
    case assessmentSGActionTypes.UPDATE_SELECTED_ASSESSMENTSG_FAILURE:
      return { ...state };

    case assessmentSGActionTypes.CLEAR_SELECTED_ASSESSMENTSG_REQUEST:
      return { ...state };
    case assessmentSGActionTypes.CLEAR_SELECTED_ASSESSMENTSG_SUCCESS:
      return { ...state, selectedAssessmentSGData: {}, isFullyLoaded: false };
    case assessmentSGActionTypes.CLEAR_SELECTED_ASSESSMENTSG_FAILURE:
      return { ...state };

    case assessmentSGActionTypes.CANCEL_SELECTED_ASSESSMENTSG_UPDATE_REQUEST:
      return { ...state };
    case assessmentSGActionTypes.CANCEL_SELECTED_ASSESSMENTSG_UPDATE_SUCCESS:
      return { ...state, selectedAssessmentSGData: action.data };
    case assessmentSGActionTypes.CANCEL_SELECTED_ASSESSMENTSG_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

import React from "react";
import TableComponent from "../../components/UserTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { userActions } from "../../store/actions/user.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { getOrganisations } from "../../helpers";

const requestTimeout = 20000;

class UserListContainer extends React.Component {
  state = {
    deleteModalOpen: false,
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    userDeletionId: "",
    searchCriteria: {
      size: 20,
      from: null,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    orgsSearchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    const sCrit = this.state.orgsSearchCriteria;
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    this.timerID = setTimeout(() => {
      if (
        this.props.userRequestStatus === undefined &&
        this.props.userRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearUserRequest();
  }

  handleDelete = () => {
    this.props.deleteUser(this.state.userDeletionId, this.props.headers);
  };

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.props.clearUserRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }

    sCrit.query = this.state.searchBarQuery;
    sCrit.fields = [
      "firstName.normalizer",
      "surname.normalizer",
      "username.normalizer"
    ];
    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      sCrit.activeOnly
    );
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteUserModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmDeleteModalVisibility = id => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      userDeletionId: id
    });
  };

  render = () => {
    const loading = this.props.loadingPage || this.props.orgsLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          <RequestFeedback
            requestStatus={this.props.userRequestStatus}
            requestMade={this.props.userRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.userRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={strings.form.feedback.userRequestUnknown}
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            userListData={this.props.userListData}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            organisationList={getOrganisations(this.props.organisationListData)}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </div>
    );
  };
}

UserListContainer.propTypes = {
  userListData: PropTypes.array.isRequired,
  userToDelete: PropTypes.string.isRequired,
  deleteUser: PropTypes.func.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getUsers: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { users, auth } = state;
  const { roleId } = auth;
  const {
    userListData,
    loadingPage,
    userToDelete,
    userRequestStatus,
    userRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = users;
  const {
    organisationListData,
    loadingPage: orgsLoadingPage
  } = state.organisations;
  return {
    userListData,
    loadingPage,
    userToDelete,
    userRequestStatus,
    userRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    organisationListData,
    orgsLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsers: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        userActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    deleteUser: (id, headers) => {
      dispatch(userActions.deleteUser(id, headers));
    },
    clearUserRequest: () => {
      dispatch(userActions.clearUserRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);

const initialStagesHead = [
  {
    position: 'left',
    body: 'Challenge/Aspiration'
  },
  {
    position: 'center',
    body: 'Stage 1'
  },
  {
    position: 'center',
    body: 'Stage 2'
  },
  {
    position: 'center',
    body: 'Stage 3'
  },
  {
    position: 'center',
    body: 'Stage 4'
  },
  {
    position: 'center',
    body: 'Identified Need'
  }
]

const initialStagesBody = [
  {
    label: 'Date Completed',
    name: 'dateCompleted'
  },
  {
    label: 'Health (General)',
    name: 'generalHealth'
  },
  {
    label: 'Health (Mental)',
    name: 'mentalHealth'
  },
  {
    label: 'Addictions',
    name: 'addictions'
  },
  {
    label: 'Education and Training',
    name: 'educationTraining'
  },
  {
    label: 'Employment',
    name: 'employment'
  },
  {
    label: 'Relationships',
    name: 'relationships'
  },
  {
    label: 'Finance',
    name: 'finance'
  },
  {
    label: 'Housing',
    name: 'housing'
  },
  {
    label: 'Attitudes & behaviours',
    name: 'attitudeBehaviour'
  },
  {
    label: 'Social & Leisure',
    name: 'socialLeisure'
  },

]

export {
  initialStagesHead,
  initialStagesBody
};

import axios from "./axios-address";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  findAddress = details => {
    let addressHeaders = this.headers;
    const config = {
      headers: addressHeaders
    };

    return new Promise((resolve, reject) => {
      axios
        .get(
          "?postcode=" + details.replace(/ /g, "") + "&api=loqate&limit=200",
          config
        )
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error fetching addresses: ", error);
          reject(error);
        });
    });
  };

  retrieveAddress = id => {
    let addressHeaders = this.headers;
    const config = {
      headers: addressHeaders
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/" + encodeURI(id), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error fetching address: ", error.message);
          reject(error);
        });
    });
  };
}

export const programmeActionTypes = {
  CREATE_PROGRAMME_REQUEST: "CREATE_PROGRAMME_REQUEST",
  CREATE_PROGRAMME_SUCCESS: "CREATE_PROGRAMME_SUCCESS",
  CREATE_PROGRAMME_FAILURE: "CREATE_PROGRAMME_FAILURE",

  CLEAR_CREATE_PROGRAMME_REQUEST: "CLEAR_CREATE_PROGRAMME_REQUEST",
  CLEAR_CREATE_PROGRAMME_FAILURE: "CLEAR_CREATE_PROGRAMME_FAILURE",
  CLEAR_CREATE_PROGRAMME_SUCCESS: "CLEAR_CREATE_PROGRAMME_SUCCESS",

  DELETE_PROGRAMME_REQUEST: "DELETE_PROGRAMME_REQUEST",
  DELETE_PROGRAMME_SUCCESS: "DELETE_PROGRAMME_SUCCESS",
  DELETE_PROGRAMME_FAILURE: "DELETE_PROGRAMME_FAILURE",

  UPDATE_SELECTED_PROGRAMME_DATA_REQUEST:
    "UPDATE_SELECTED_PROGRAMME_DATA_REQUEST",
  UPDATE_SELECTED_PROGRAMME_DATA_SUCCESS:
    "UPDATE_SELECTED_PROGRAMME_DATA_SUCCESS",
  UPDATE_SELECTED_PROGRAMME_DATA_FAILURE:
    "UPDATE_SELECTED_PROGRAMME_DATA_FAILURE",

  UPDATE_SELECTED_PROGRAMME_REQUEST: "UPDATE_SELECTED_PROGRAMME_REQUEST",
  UPDATE_SELECTED_PROGRAMME_SUCCESS: "UPDATE_SELECTED_PROGRAMME_SUCCESS",
  UPDATE_SELECTED_PROGRAMME_FAILURE: "UPDATE_SELECTED_PROGRAMME_FAILURE",

  CLEAR_SELECTED_PROGRAMME_REQUEST: "CLEAR_SELECTED_PROGRAMME_REQUEST",
  CLEAR_SELECTED_PROGRAMME_SUCCESS: "CLEAR_SELECTED_PROGRAMME_SUCCESS",
  CLEAR_SELECTED_PROGRAMME_FAILURE: "CLEAR_SELECTED_PROGRAMME_FAILURE",

  CANCEL_SELECTED_PROGRAMME_UPDATE_REQUEST:
    "CANCEL_SELECTED_PROGRAMME_UPDATE_REQUEST",
  CANCEL_SELECTED_PROGRAMME_UPDATE_SUCCESS:
    "CANCEL_SELECTED_PROGRAMME_UPDATE_SUCCESS",
  CANCEL_SELECTED_PROGRAMME_UPDATE_FAILURE:
    "CANCEL_SELECTED_PROGRAMME_UPDATE_FAILURE",

  GET_PROGRAMMES_REQUEST: "GET_PROGRAMMES_REQUEST",
  GET_PROGRAMMES_SUCCESS: "GET_PROGRAMMES_SUCCESS",
  GET_PROGRAMMES_FAILURE: "GET_PROGRAMMES_FAILURE",

  UPDATE_PROGRAMME_REQUEST: "UPDATE_PROGRAMME_REQUEST",
  UPDATE_PROGRAMME_SUCCESS: "UPDATE_PROGRAMME_SUCCESS",
  UPDATE_PROGRAMME_FAILURE: "UPDATE_PROGRAMME_FAILURE",

  GET_PROGRAMME_BY_ID_REQUEST: "GET_PROGRAMME_BY_ID_REQUEST",
  GET_PROGRAMME_BY_ID_SUCCESS: "GET_PROGRAMME_BY_ID_SUCCESS",
  GET_PROGRAMME_BY_ID_FAILURE: "GET_PROGRAMME_BY_ID_FAILURE",

  GENERATE_PROGRAMME_ID_REQUEST: "GENERATE_PROGRAMME_ID_REQUEST",
  GENERATE_PROGRAMME_ID_SUCCESS: "GENERATE_PROGRAMME_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  GET_PROGRAMME_OUTCOMES_REQUEST: "GET_PROGRAMME_OUTCOMES_REQUEST",
  GET_PROGRAMME_OUTCOMES_SUCCESS: "GET_PROGRAMME_OUTCOMES_SUCCESS",
  GET_PROGRAMME_OUTCOMES_FAILURE: "GET_PROGRAMME_OUTCOMES_FAILURE"
};

export const authActionTypes = {
  GENERATE_SYSTEM_HEADERS_SUCCESS: "GENERATE_SYSTEM_HEADERS_SUCCESS",
  GENERATE_SYSTEM_HEADERS_FAILURE: "GENERATE_SYSTEM_HEADERS_FAILURE",

  SET_SYSTEM_HEADER_REQUEST: "SET_SYSTEM_HEADER_REQUEST",
  SET_SYSTEM_HEADER_SUCCESS: "SET_SYSTEM_HEADER_SUCCESS",
  SET_SYSTEM_HEADER_FAILURE: "SET_SYSTEM_HEADER_FAILURE",

  REFRESH_TOKENS_REQUEST: "REFRESH_TOKENS_REQUEST",
  REFRESH_TOKENS_SUCCESS: "REFRESH_TOKENS_SUCCESS",
  REFRESH_TOKENS_FAILURE: "REFRESH_TOKENS_FAILURE",

  CHECK_AUTHENTICATION_STATUS_REQUEST: "CHECK_AUTHENTICATION_STATUS_REQUEST",
  CHECK_AUTHENTICATION_STATUS_SUCCESS: "CHECK_AUTHENTICATION_STATUS_SUCCESS",
  CHECK_AUTHENTICATION_STATUS_FAILURE: "CHECK_AUTHENTICATION_STATUS_FAILURE",

  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  SIGNIN_REQUEST: "SIGNIN_REQUEST",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",

  SIGNOUT_REQUEST: "SIGNOUT_REQUEST",
  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SIGNOUT_FAILURE: "SIGNOUT_FAILURE",

  CHECK_SIGNIN_STATUS: "CHECK_SIGNIN_STATUS",
  REFRESH_SIGNIN_STATUS: "REFRESH_SIGNIN_STATUS",
  REFRESH_SIGNIN_STATUS_FAILURE: "REFRESH_SIGNIN_STATUS_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  PURGE_SYSTEM_HEADERS_REQUEST: "PURGE_SYSTEM_HEADERS_REQUEST",
  PURGE_SYSTEM_HEADERS_SUCCESS: "PURGE_SYSTEM_HEADERS_SUCCESS",

  SET_SESSION_EXPIRED_REQUEST: "SET_SESSION_EXPIRED_REQUEST",
  SET_SESSION_EXPIRED_SUCCESS: "SET_SESSION_EXPIRED_SUCCESS"
};

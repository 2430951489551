export const engagementActionTypes = {
  CREATE_ENGAGEMENT_REQUEST: "CREATE_ENGAGEMENT_REQUEST",
  CREATE_ENGAGEMENT_SUCCESS: "CREATE_ENGAGEMENT_SUCCESS",
  CREATE_ENGAGEMENT_FAILURE: "CREATE_ENGAGEMENT_FAILURE",

  CLEAR_CREATE_ENGAGEMENT_REQUEST: "CLEAR_CREATE_ENGAGEMENT_REQUEST",
  CLEAR_CREATE_ENGAGEMENT_FAILURE: "CLEAR_CREATE_ENGAGEMENT_FAILURE",
  CLEAR_CREATE_ENGAGEMENT_SUCCESS: "CLEAR_CREATE_ENGAGEMENT_SUCCESS",

  DELETE_ENGAGEMENT_REQUEST: "DELETE_ENGAGEMENT_REQUEST",
  DELETE_ENGAGEMENT_SUCCESS: "DELETE_ENGAGEMENT_SUCCESS",
  DELETE_ENGAGEMENT_FAILURE: "DELETE_ENGAGEMENT_FAILURE",

  UPDATE_SELECTED_ENGAGEMENT_DATA_REQUEST:
    "UPDATE_SELECTED_ENGAGEMENT_DATA_REQUEST",
  UPDATE_SELECTED_ENGAGEMENT_DATA_SUCCESS:
    "UPDATE_SELECTED_ENGAGEMENT_DATA_SUCCESS",
  UPDATE_SELECTED_ENGAGEMENT_DATA_FAILURE:
    "UPDATE_SELECTED_ENGAGEMENT_DATA_FAILURE",

  UPDATE_SELECTED_ENGAGEMENT_REQUEST: "UPDATE_SELECTED_ENGAGEMENT_REQUEST",
  UPDATE_SELECTED_ENGAGEMENT_SUCCESS: "UPDATE_SELECTED_ENGAGEMENT_SUCCESS",
  UPDATE_SELECTED_ENGAGEMENT_FAILURE: "UPDATE_SELECTED_ENGAGEMENT_FAILURE",

  CLEAR_SELECTED_ENGAGEMENT_REQUEST: "CLEAR_SELECTED_ENGAGEMENT_REQUEST",
  CLEAR_SELECTED_ENGAGEMENT_SUCCESS: "CLEAR_SELECTED_ENGAGEMENT_SUCCESS",
  CLEAR_SELECTED_ENGAGEMENT_FAILURE: "CLEAR_SELECTED_ENGAGEMENT_FAILURE",

  CANCEL_SELECTED_ENGAGEMENT_UPDATE_REQUEST:
    "CANCEL_SELECTED_ENGAGEMENT_UPDATE_REQUEST",
  CANCEL_SELECTED_ENGAGEMENT_UPDATE_SUCCESS:
    "CANCEL_SELECTED_ENGAGEMENT_UPDATE_SUCCESS",
  CANCEL_SELECTED_ENGAGEMENT_UPDATE_FAILURE:
    "CANCEL_SELECTED_ENGAGEMENT_UPDATE_FAILURE",

  GET_ENGAGEMENTS_REQUEST: "GET_ENGAGEMENTS_REQUEST",
  GET_ENGAGEMENTS_SUCCESS: "GET_ENGAGEMENTS_SUCCESS",
  GET_ENGAGEMENTS_FAILURE: "GET_ENGAGEMENTS_FAILURE",

  GET_ENGAGEMENTS_BY_REFERRAL_REQUEST: "GET_ENGAGEMENTS_BY_REFERRAL_REQUEST",
  GET_ENGAGEMENTS_BY_REFERRAL_SUCCESS: "GET_ENGAGEMENTS_BY_REFERRAL_SUCCESS",
  GET_ENGAGEMENTS_BY_REFERRAL_FAILURE: "GET_ENGAGEMENTS_BY_REFERRAL_FAILURE",

  GET_ENGAGEMENTS_BY_PROGRAMME_REQUEST: "GET_ENGAGEMENTS_BY_PROGRAMME_REQUEST",
  GET_ENGAGEMENTS_BY_PROGRAMME_SUCCESS: "GET_ENGAGEMENTS_BY_PROGRAMME_SUCCESS",
  GET_ENGAGEMENTS_BY_PROGRAMME_FAILURE: "GET_ENGAGEMENTS_BY_PROGRAMME_FAILURE",

  UPDATE_ENGAGEMENT_REQUEST: "UPDATE_ENGAGEMENT_REQUEST",
  UPDATE_ENGAGEMENT_SUCCESS: "UPDATE_ENGAGEMENT_SUCCESS",
  UPDATE_ENGAGEMENT_FAILURE: "UPDATE_ENGAGEMENT_FAILURE",

  GET_ENGAGEMENT_BY_ID_REQUEST: "GET_ENGAGEMENT_BY_ID_REQUEST",
  GET_ENGAGEMENT_BY_ID_SUCCESS: "GET_ENGAGEMENT_BY_ID_SUCCESS",
  GET_ENGAGEMENT_BY_ID_FAILURE: "GET_ENGAGEMENT_BY_ID_FAILURE",

  GENERATE_ENGAGEMENT_ID_REQUEST: "GENERATE_ENGAGEMENT_ID_REQUEST",
  GENERATE_ENGAGEMENT_ID_SUCCESS: "GENERATE_ENGAGEMENT_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};

import React from "react";
import { Grid, Message } from "semantic-ui-react";
import PropTypes from "prop-types";

class RequestFeedback extends React.Component {
  render = () => {
    return (
      <Grid className={this.props.className || "requestFeedback"} columns={1}>
        {this.props.requestMade &&
          this.props.requestStatus === undefined &&
          !this.props.unknownRequestStatus && (
            <Grid.Column>
              <Message
                className="processingRequest"
                content={this.props.processingFeedbackMessage}
                header={this.props.statusFeedbackMessage}
              />
            </Grid.Column>
          )}
        {this.props.requestMade &&
          this.props.requestStatus &&
          !this.props.unknownRequestStatus && (
            <Grid.Column>
              <Message
                className="requestSuccess"
                success
                header={this.props.successFeedbackMessage}
                content={
                  this.props.successMessage +
                  " " +
                  (this.props.optionalRefreshMessage || "")
                }
              />
            </Grid.Column>
          )}
        {this.props.requestMade &&
          this.props.requestStatus === false &&
          !this.props.unknownRequestStatus && (
            <Grid.Column>
              <Message
                className="requestFailure"
                error
                header={this.props.failureMessage}
                list={
                  this.props.errorDetails &&
                  Array.isArray(this.props.errorDetails)
                    ? this.props.errorDetails.map(error => {
                        return error;
                      })
                    : []
                }
                content={
                  this.props.errorDetails &&
                  !Array.isArray(this.props.errorDetails)
                    ? this.props.errorDetails
                    : ""
                }
              />
            </Grid.Column>
          )}
        {this.props.unknownRequestStatus &&
          this.props.requestStatus === undefined && (
            <Grid.Column>
              <Message
                className="requestUnknown"
                header={this.props.statusFeedbackMessage}
                content={this.props.unknownFeedbackMessage}
              />
            </Grid.Column>
          )}
      </Grid>
    );
  };
}

RequestFeedback.propTypes = {
  requestStatus: PropTypes.bool,
  requestMade: PropTypes.bool.isRequired,
  unknownRequestStatus: PropTypes.bool.isRequired,
  successMessage: PropTypes.string.isRequired,
  failureMessage: PropTypes.string.isRequired,
  processingFeedbackMessage: PropTypes.string.isRequired,
  unknownFeedbackMessage: PropTypes.string.isRequired,
  statusFeedbackMessage: PropTypes.string.isRequired,
  successFeedbackMessage: PropTypes.string.isRequired,
  optionalRefreshMessage: PropTypes.string
};

export default RequestFeedback;

export const activityActionTypes = {
  CREATE_ACTIVITY_REQUEST: "CREATE_ACTIVITY_REQUEST",
  CREATE_ACTIVITY_SUCCESS: "CREATE_ACTIVITY_SUCCESS",
  CREATE_ACTIVITY_FAILURE: "CREATE_ACTIVITY_FAILURE",

  CLEAR_CREATE_ACTIVITY_REQUEST: "CLEAR_CREATE_ACTIVITY_REQUEST",
  CLEAR_CREATE_ACTIVITY_FAILURE: "CLEAR_CREATE_ACTIVITY_FAILURE",
  CLEAR_CREATE_ACTIVITY_SUCCESS: "CLEAR_CREATE_ACTIVITY_SUCCESS",

  DELETE_ACTIVITY_REQUEST: "DELETE_ACTIVITY_REQUEST",
  DELETE_ACTIVITY_SUCCESS: "DELETE_ACTIVITY_SUCCESS",
  DELETE_ACTIVITY_FAILURE: "DELETE_ACTIVITY_FAILURE",

  UPDATE_SELECTED_ACTIVITY_DATA_REQUEST:
    "UPDATE_SELECTED_ACTIVITY_DATA_REQUEST",
  UPDATE_SELECTED_ACTIVITY_DATA_SUCCESS:
    "UPDATE_SELECTED_ACTIVITY_DATA_SUCCESS",
  UPDATE_SELECTED_ACTIVITY_DATA_FAILURE:
    "UPDATE_SELECTED_ACTIVITY_DATA_FAILURE",

  UPDATE_SELECTED_ACTIVITY_REQUEST: "UPDATE_SELECTED_ACTIVITY_REQUEST",
  UPDATE_SELECTED_ACTIVITY_SUCCESS: "UPDATE_SELECTED_ACTIVITY_SUCCESS",
  UPDATE_SELECTED_ACTIVITY_FAILURE: "UPDATE_SELECTED_ACTIVITY_FAILURE",

  CLEAR_SELECTED_ACTIVITY_REQUEST: "CLEAR_SELECTED_ACTIVITY_REQUEST",
  CLEAR_SELECTED_ACTIVITY_SUCCESS: "CLEAR_SELECTED_ACTIVITY_SUCCESS",
  CLEAR_SELECTED_ACTIVITY_FAILURE: "CLEAR_SELECTED_ACTIVITY_FAILURE",

  CANCEL_SELECTED_ACTIVITY_UPDATE_REQUEST:
    "CANCEL_SELECTED_ACTIVITY_UPDATE_REQUEST",
  CANCEL_SELECTED_ACTIVITY_UPDATE_SUCCESS:
    "CANCEL_SELECTED_ACTIVITY_UPDATE_SUCCESS",
  CANCEL_SELECTED_ACTIVITY_UPDATE_FAILURE:
    "CANCEL_SELECTED_ACTIVITY_UPDATE_FAILURE",

  GET_ACTIVITIES_REQUEST: "GET_ACTIVITIES_REQUEST",
  GET_ACTIVITIES_SUCCESS: "GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_FAILURE: "GET_ACTIVITIES_FAILURE",

  UPDATE_ACTIVITY_REQUEST: "UPDATE_ACTIVITY_REQUEST",
  UPDATE_ACTIVITY_SUCCESS: "UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ACTIVITY_FAILURE: "UPDATE_ACTIVITY_FAILURE",

  GET_ACTIVITY_BY_ID_REQUEST: "GET_ACTIVITY_BY_ID_REQUEST",
  GET_ACTIVITY_BY_ID_SUCCESS: "GET_ACTIVITY_BY_ID_SUCCESS",
  GET_ACTIVITY_BY_ID_FAILURE: "GET_ACTIVITY_BY_ID_FAILURE",

  GENERATE_ACTIVITY_ID_REQUEST: "GENERATE_ACTIVITY_ID_REQUEST",
  GENERATE_ACTIVITY_ID_SUCCESS: "GENERATE_ACTIVITY_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  GET_ACTIVITIES_BY_ENGAGEMENT_REQUEST: "GET_ACTIVITIES_BY_ENGAGEMENT_REQUEST",
  GET_ACTIVITIES_BY_ENGAGEMENT_SUCCESS: "GET_ACTIVITIES_BY_ENGAGEMENT_SUCCESS",
  GET_ACTIVITIES_BY_ENGAGEMENT_FAILURE: "GET_ACTIVITIES_BY_ENGAGEMENT_FAILURE",

  GET_ACTIVITY_TYPE_COUNT_REQUEST: "GET_ACTIVITY_TYPE_COUNT_REQUEST",
  GET_ACTIVITY_TYPE_COUNT_SUCCESS: "GET_ACTIVITY_TYPE_COUNT_SUCCESS",
  GET_ACTIVITY_TYPE_COUNT_FAILURE: "GET_ACTIVITY_TYPE_COUNT_FAILURE"
};

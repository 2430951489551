const getOrganisations = organisationListData => {
  let inputData = [];
  if (organisationListData) {
    organisationListData.forEach((result, index) => {
      if (result["_source"]) {
        inputData.push(result["_source"]);
      }
    });
  }
  let dropdownFields = [];
  let i = 0;
  inputData.forEach((object, key) => {
    dropdownFields.push({
      key: i,
      text: object.organisationName,
      value: object.organisationId,
      maintype: object.mainType
    });
    i++;
  });
  return dropdownFields;
};

export default getOrganisations;

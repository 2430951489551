import { employmentActionTypes } from "../actions/employment.actionTypes";

const initialState = {
  loadingPage: false,
  selectedEmploymentData: {},
  employmentListData: [{}],
  totalResults: 0,
  employmentToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  employmentRequestStatus: undefined,
  employmentRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case employmentActionTypes.CREATE_EMPLOYMENT_REQUEST:
      return {
        ...state,
        employmentRequestStatus: undefined,
        employmentRequestMade: true
      };
    case employmentActionTypes.CREATE_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        employmentRequestStatus: true
      };
    case employmentActionTypes.CREATE_EMPLOYMENT_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        employmentRequestStatus: false
      };

    case employmentActionTypes.CLEAR_CREATE_EMPLOYMENT_REQUEST:
      return { ...state };
    case employmentActionTypes.CLEAR_CREATE_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        employmentRequestMade: false,
        employmentRequestStatus: undefined
      };
    case employmentActionTypes.CLEAR_CREATE_EMPLOYMENT_FAILURE:
      return { ...state };

    case employmentActionTypes.UPDATE_EMPLOYMENT_REQUEST:
      return {
        ...state,
        loadingPage: true,
        employmentRequestStatus: undefined,
        employmentRequestMade: true
      };
    case employmentActionTypes.UPDATE_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        employmentRequestStatus: true,
        selectedEmploymentData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case employmentActionTypes.UPDATE_EMPLOYMENT_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        employmentRequestStatus: false,
        selectedEmploymentData: action.initialDetails,
        errorDetails: details
      };

    case employmentActionTypes.DELETE_EMPLOYMENT_REQUEST:
      return {
        ...state,
        employmentRequestStatus: undefined,
        employmentRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case employmentActionTypes.DELETE_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        employmentRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case employmentActionTypes.DELETE_EMPLOYMENT_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        employmentRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case employmentActionTypes.GET_EMPLOYMENTS_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case employmentActionTypes.GET_EMPLOYMENTS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newEmployment = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newEmployment;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        employmentListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case employmentActionTypes.GET_EMPLOYMENTS_FAILURE:
      return { ...state, employmentListData: [{}], loadingPage: false };

    case employmentActionTypes.GET_EMPLOYMENTS_BY_PROGRAMME_REQUEST:
      return { ...state, loadingPage: true };
    case employmentActionTypes.GET_EMPLOYMENTS_BY_PROGRAMME_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.employments) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.employments.slice();
        rawHits.forEach((result, index) => {
          let newEmployment = Object.assign({}, result);
          rawHits[index] = {};
          rawHits[index]["_source"] = newEmployment;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        employmentListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case employmentActionTypes.GET_EMPLOYMENTS_BY_PROGRAMME_FAILURE:
      return { ...state, employmentListData: [{}], loadingPage: false };

    case employmentActionTypes.GET_EMPLOYMENT_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        employmentRequestStatus: undefined
      };
    case employmentActionTypes.GET_EMPLOYMENT_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        employmentRequestStatus: true,
        selectedEmploymentData: action.response.Body.Item,
        loadingPage: false
      };
    case employmentActionTypes.GET_EMPLOYMENT_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedEmploymentData: {},
        employmentRequestStatus: false,
        employmentRequestMade: true
      };

    case employmentActionTypes.UPDATE_SELECTED_EMPLOYMENT_REQUEST:
      return { ...state };
    case employmentActionTypes.UPDATE_SELECTED_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        selectedEmploymentData: {
          ...state.selectedEmploymentData,
          [action.key]: action.value
        }
      };
    case employmentActionTypes.UPDATE_SELECTED_EMPLOYMENT_FAILURE:
      return { ...state };

    case employmentActionTypes.CLEAR_SELECTED_EMPLOYMENT_REQUEST:
      return { ...state };
    case employmentActionTypes.CLEAR_SELECTED_EMPLOYMENT_SUCCESS:
      return { ...state, selectedEmploymentData: {} };
    case employmentActionTypes.CLEAR_SELECTED_EMPLOYMENT_FAILURE:
      return { ...state };

    case employmentActionTypes.CANCEL_SELECTED_EMPLOYMENT_UPDATE_REQUEST:
      return { ...state };
    case employmentActionTypes.CANCEL_SELECTED_EMPLOYMENT_UPDATE_SUCCESS:
      return { ...state, selectedEmploymentData: action.data };
    case employmentActionTypes.CANCEL_SELECTED_EMPLOYMENT_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

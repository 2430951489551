import {
  strings,
  signupMeetingOutcomeOptions,
  signupMeetingOutcomeOptionsPreReferral
} from "../resources";

const calculateSignUpOutcomeOptions = referralDetails => {
  let outcomeOptions = signupMeetingOutcomeOptionsPreReferral;
  if (referralDetails && referralDetails.consent) {
    if (
      referralDetails.area === strings.areas.NRS &&
      referralDetails.communityOrganisation
    ) {
      outcomeOptions = signupMeetingOutcomeOptions;
    } else if (referralDetails.area === strings.areas.NRNE) {
      outcomeOptions = signupMeetingOutcomeOptions;
    }
  }
  return outcomeOptions;
};

export default calculateSignUpOutcomeOptions;

export const systemHeaders = {
  systemHeader_sysref: { type: String, required: true, default: "" }, // DrDM
  systemHeader_subsysref: { type: String, required: true, default: "" }, // e.g. Editing user info, deleting user, schedule/task system
  systemHeader_client: { type: String, required: true, default: "" }, // DrDM
  systemHeader_userid: { type: String, required: true, default: "" }, // UserID of user sending request
  systemHeader_username: { type: String, required: true, default: "" }, // Username of user sending request
  systemHeader_permissions: { type: String, required: true, default: "" }, // Permissions of the user e.g. Administrator, Staff - base64 string
  systemHeader_date: { type: String, required: true, default: "" }, // Date request made
  systemHeader_correlationId: { type: String, required: true, default: "" }, // CorrelationID is the microservice being called
  systemHeader_parents: { type: String, required: true, default: "" } // ?
};

export const organisationActionTypes = {
  CREATE_ORGANISATION_REQUEST: "CREATE_ORGANISATION_REQUEST",
  CREATE_ORGANISATION_SUCCESS: "CREATE_ORGANISATION_SUCCESS",
  CREATE_ORGANISATION_FAILURE: "CREATE_ORGANISATION_FAILURE",

  CLEAR_CREATE_ORGANISATION_REQUEST: "CLEAR_CREATE_ORGANISATION_REQUEST",
  CLEAR_CREATE_ORGANISATION_FAILURE: "CLEAR_CREATE_ORGANISATION_FAILURE",
  CLEAR_CREATE_ORGANISATION_SUCCESS: "CLEAR_CREATE_ORGANISATION_SUCCESS",

  DELETE_ORGANISATION_REQUEST: "DELETE_ORGANISATION_REQUEST",
  DELETE_ORGANISATION_SUCCESS: "DELETE_ORGANISATION_SUCCESS",
  DELETE_ORGANISATION_FAILURE: "DELETE_ORGANISATION_FAILURE",

  UPDATE_SELECTED_ORGANISATION_DATA_REQUEST:
    "UPDATE_SELECTED_ORGANISATION_DATA_REQUEST",
  UPDATE_SELECTED_ORGANISATION_DATA_SUCCESS:
    "UPDATE_SELECTED_ORGANISATION_DATA_SUCCESS",
  UPDATE_SELECTED_ORGANISATION_DATA_FAILURE:
    "UPDATE_SELECTED_ORGANISATION_DATA_FAILURE",

  UPDATE_SELECTED_ORGANISATION_CONTACT_REQUEST:
    "UPDATE_SELECTED_ORGANISATION_CONTACT_REQUEST",
  UPDATE_SELECTED_ORGANISATION_CONTACT_SUCCESS:
    "UPDATE_SELECTED_ORGANISATION_CONTACT_SUCCESS",
  UPDATE_SELECTED_ORGANISATION_CONTACT_FAILURE:
    "UPDATE_SELECTED_ORGANISATION_CONTACT_FAILURE",

  UPDATE_SELECTED_ORGANISATION_REQUEST: "UPDATE_SELECTED_ORGANISATION_REQUEST",
  UPDATE_SELECTED_ORGANISATION_SUCCESS: "UPDATE_SELECTED_ORGANISATION_SUCCESS",
  UPDATE_SELECTED_ORGANISATION_FAILURE: "UPDATE_SELECTED_ORGANISATION_FAILURE",

  CLEAR_SELECTED_ORGANISATION_REQUEST: "CLEAR_SELECTED_ORGANISATION_REQUEST",
  CLEAR_SELECTED_ORGANISATION_SUCCESS: "CLEAR_SELECTED_ORGANISATION_SUCCESS",
  CLEAR_SELECTED_ORGANISATION_FAILURE: "CLEAR_SELECTED_ORGANISATION_FAILURE",

  CANCEL_SELECTED_ORGANISATION_UPDATE_REQUEST:
    "CANCEL_SELECTED_ORGANISATION_UPDATE_REQUEST",
  CANCEL_SELECTED_ORGANISATION_UPDATE_SUCCESS:
    "CANCEL_SELECTED_ORGANISATION_UPDATE_SUCCESS",
  CANCEL_SELECTED_ORGANISATION_UPDATE_FAILURE:
    "CANCEL_SELECTED_ORGANISATION_UPDATE_FAILURE",

  GET_ORGANISATIONS_REQUEST: "GET_ORGANISATIONS_REQUEST",
  GET_ORGANISATIONS_SUCCESS: "GET_ORGANISATIONS_SUCCESS",
  GET_ORGANISATIONS_FAILURE: "GET_ORGANISATIONS_FAILURE",

  UPDATE_ORGANISATION_REQUEST: "UPDATE_ORGANISATION_REQUEST",
  UPDATE_ORGANISATION_SUCCESS: "UPDATE_ORGANISATION_SUCCESS",
  UPDATE_ORGANISATION_FAILURE: "UPDATE_ORGANISATION_FAILURE",

  GET_ORGANISATION_BY_ID_REQUEST: "GET_ORGANISATION_BY_ID_REQUEST",
  GET_ORGANISATION_BY_ID_SUCCESS: "GET_ORGANISATION_BY_ID_SUCCESS",
  GET_ORGANISATION_BY_ID_FAILURE: "GET_ORGANISATION_BY_ID_FAILURE",

  GENERATE_ORGANISATION_ID_REQUEST: "GENERATE_ORGANISATION_ID_REQUEST",
  GENERATE_ORGANISATION_ID_SUCCESS: "GENERATE_ORGANISATION_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};

import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings, yesNoOptions } from "../../resources";
import { convertFromIso } from "../../helpers/index";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormInput
} from "../validation";
import "../css/ReferralComponents.scss";

class MatchingFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.matchingHeader}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.warmHandover}
              viewData={this.props.selectedReferralData.warmHandover}
              dropdownLabel={strings.form.label.warmHandover}
              dropdownName="warmHandover"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.warmHandover}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.warmHandover}
              dropdownDisabled={this.props.mode !== "view"}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.matchedToMentor}
              viewData={this.props.selectedReferralData.matchedToMentor}
              dropdownLabel={strings.form.label.matchedToMentor}
              dropdownName="matchedToMentor"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.matchedToMentor}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.matchedToMentor}
              dropdownDisabled={this.props.mode !== "view"}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.reasonNotMatched}
              inputLabel={strings.form.label.reasonNotMatched}
              inputName="reasonNotMatched"
              inputPlaceholder={strings.form.placeholder.reasonNotMatched}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.reasonNotMatched}
              disabled={this.props.mode !== "view"}
              mode={this.props.mode}
              required={false}
            />
            <ValidatedDateInput
              inputId="dateMatched"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.dateMatched}
              inputName="dateMatched"
              inputPlaceholder={strings.form.placeholder.dateMatched}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedReferralData.dateMatched
                  ? convertFromIso(this.props.selectedReferralData.dateMatched)
                  : ""
              }
              disabled={
                this.props.mode === "view" ||
                this.props.area === strings.areas.NRS
              }
              iconPosition="left"
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.dateMatched}
              formFieldWidth={8}
              mode={this.props.mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };
}

MatchingFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default MatchingFields;

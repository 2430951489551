import React from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { BasicModal } from "../";
import PropTypes from "prop-types";

import { ValidatedFormInput } from ".";

const ValidatedMultipleContacts = props => {
  return (
    <>
      <Table className="contactsTable" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign="center">
              Contact
            </Table.HeaderCell>
            <Table.HeaderCell width={5}>
              {props.contactNameString}
            </Table.HeaderCell>
            <Table.HeaderCell width={5}>
              {props.contactPhoneString}
            </Table.HeaderCell>
            <Table.HeaderCell width={5}>
              {props.contactEmailString}
            </Table.HeaderCell>
            <Table.HeaderCell width={5} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.selectedOrganisationData.contacts
            ? props.selectedOrganisationData.contacts.map((val, idx) => {
                let contactNameId = `contactName-${idx}`,
                  contactPhoneId = `contactPhone-${idx}`,
                  contactEmailId = `contactEmail-${idx}`;
                return (
                  <Table.Row key={idx}>
                    <Table.Cell textAlign="center">
                      <span>{idx + 1}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <ValidatedFormInput
                        formInvalid={props.formInvalid}
                        inputData={
                          props.selectedOrganisationData.contacts[idx]
                            .contactName
                        }
                        inputLabel=""
                        inputPlaceholder={props.contactNamePlaceholder}
                        inputClassName="mini"
                        inputName={contactNameId}
                        formFieldWidth={16}
                        handleChange={props.handleContactChange}
                        validationResult={
                          props.validationResults.contacts
                            ? props.validationResults.contacts[idx]
                              ? props.validationResults.contacts[idx]
                                  .contactName
                              : undefined
                            : undefined
                        }
                        mode={props.mode}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <ValidatedFormInput
                        formInvalid={props.formInvalid}
                        inputData={
                          props.selectedOrganisationData.contacts[idx]
                            .contactPhone
                        }
                        inputLabel=""
                        inputPlaceholder={props.contactPhonePlaceholder}
                        inputClassName="mini"
                        inputName={contactPhoneId}
                        formFieldWidth={16}
                        onKeyPress={props.allowNumericalInput}
                        handleChange={props.handleContactChange}
                        validationResult={
                          props.validationResults.contacts
                            ? props.validationResults.contacts[idx]
                              ? props.validationResults.contacts[idx]
                                  .contactPhone
                              : undefined
                            : undefined
                        }
                        mode={props.mode}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <ValidatedFormInput
                        formInvalid={props.formInvalid}
                        inputData={
                          props.selectedOrganisationData.contacts[idx]
                            .contactEmail
                        }
                        inputLabel=""
                        inputPlaceholder={props.contactEmailPlaceholder}
                        inputClassName="mini"
                        inputName={contactEmailId}
                        formFieldWidth={16}
                        handleChange={props.handleContactChange}
                        validationResult={
                          props.validationResults.contacts
                            ? props.validationResults.contacts[idx]
                              ? props.validationResults.contacts[idx]
                                  .contactEmail
                              : undefined
                            : undefined
                        }
                        mode={props.mode}
                      />
                    </Table.Cell>
                    {props.mode !== "view" && (
                      <Table.Cell textAlign="center">
                        <Icon
                          className="contactDeleteIcon"
                          name="trash outline alternate"
                          onClick={() => props.toggleDeleteModal(idx)}
                        />
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })
            : undefined}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell colSpan="4">
              <Button
                className="addContactButton"
                floated="right"
                icon
                labelPosition="left"
                size="small"
                onClick={props.addContact}
              >
                <Icon name="plus" /> Add contact
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <BasicModal
        confirmMessage={props.deleteContactMessage}
        handleModalConfirm={props.handleModalConfirm}
        modalClassName="deletionContactModal"
        modalOpen={props.deleteModalOpen}
        showNoButton={true}
        showOkButton={false}
        showYesButton={true}
        toggleModal={props.toggleDeleteModal}
        noLabel={props.noLabel}
        yesLabel={props.yesLabel}
        okLabel={props.okLabel}
      />
    </>
  );
};

ValidatedMultipleContacts.propTypes = {
  addContact: PropTypes.func.isRequired,
  allowNumericalInput: PropTypes.func.isRequired,
  contactEmailPlaceholder: PropTypes.string.isRequired,
  contactEmailString: PropTypes.string.isRequired,
  contactNamePlaceholder: PropTypes.string.isRequired,
  contactNameString: PropTypes.string.isRequired,
  contactPhonePlaceholder: PropTypes.string.isRequired,
  contactPhoneString: PropTypes.string.isRequired,
  deleteContactMessage: PropTypes.string.isRequired,
  deleteModalOpen: PropTypes.bool.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  handleContactChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  mode: PropTypes.string,
  noLabel: PropTypes.string.isRequired,
  okLabel: PropTypes.string.isRequired,
  selectedOrganisationData: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  yesLabel: PropTypes.string.isRequired
};

export default ValidatedMultipleContacts;

const mapSearchResultsToRows = list => {
  let data = [];
  if (list) {
    list.forEach((result, index) => {
      if (result["_source"]) {
        data.push(result["_source"]);
      }
    });
  }
  return data;
};

const filterUnique = (data, attribute) => {
  return Object.values(
    data.reduce((c, e) => {
      if (!c[e[attribute]]) {
        c[e[attribute]] = e;
      }
      return c;
    }, {})
  );
};

const removeDuplicates = (mentors, key) => {
  return [...new Map(mentors.map(mentor => [key(mentor), mentor])).values()];
};

export default {
  mapSearchResultsToRows,
  filterUnique,
  removeDuplicates
};

import React from "react";
import { Form, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./Layout/css/SearchBar.scss";

class SearchBar extends React.Component {
  render = () => {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Input
          id="searchBar"
          value={this.props.inputValue}
          name="searchBarQuery"
          aria-label="Search through content"
          onChange={this.props.handleChange}
          action={{
            id: "searchButton",
            labelPosition: "right",
            icon: "search",
            content: "Search",
            onClick: this.props.handleSubmit
          }}
          autoFocus
        />
      </Form>
    );
  };
}

SearchBar.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired
};

export default SearchBar;

import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

class InputModal extends React.Component {
  handleClose = () => {
    this.props.toggleModal();
    this.props.resetDataOnModalClose();
  };

  handleSubmit = () => {
    this.props.toggleModal();
    this.props.handleModalConfirm(this.props.modalClassName);
  };

  render = () => {
    return (
      <Modal
        className={this.props.modalClassName}
        open={this.props.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content className="modalContent">
          <h3>{this.props.confirmMessage}</h3>
          {this.props.input}
          {this.props.children}
        </Modal.Content>

        <Modal.Actions>
          <Button
            basic
            color="green"
            onClick={this.handleSubmit}
            style={{ display: this.props.showOkButton ? "" : "none" }}
            disabled={
              (this.props.inputRequired && !this.props.inputValue) ||
              this.props.disabled
            }
          >
            <Icon name="checkmark" /> {this.props.okLabel}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

InputModal.propTypes = {
  confirmMessage: PropTypes.string.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  modalClassName: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool,
  showOkButton: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  okLabel: PropTypes.string.isRequired,
  inputRequired: PropTypes.bool,
  inputValue: PropTypes.string
};

export default InputModal;

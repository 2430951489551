export const allowNumericalInput = e => {
  e = e || window.event;
  const charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
  const charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
};

export const allowCurrencyInput = e => {
  e = e || window.event;
  const charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
  const charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9.]+$/)) e.preventDefault();
};

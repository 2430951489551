const costTypeOptions = [
  { key: 0, text: "Childcare", value: "Childcare" },
  { key: 1, text: "Clothing", value: "Clothing" },
  { key: 2, text: "Tools", value: "Tools" },
  { key: 3, text: "Travel", value: "Travel" },
  { key: 4, text: "Vouchers", value: "Vouchers" },
  { key: 5, text: "Other", value: "Other" },
  { key: 6, text: "Internal Training", value: "Internal Training" },
  { key: 7, text: "External Training", value: "External Training" },
  { key: 8, text: "Gym membership", value: "Gym membership" },
  { key: 9, text: "Driving License", value: "Driving License" },
  { key: 10, text: "Counselling session", value: "Counselling session" },
  { key: 11, text: "ID card", value: "ID card" },
  { key: 12, text: "Utility bill top-up", value: "Utility bill top-up" }
];

export { costTypeOptions };

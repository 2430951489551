const filterOutUndesiredProps = arrayToFilter => {
  const allowedProps = ["key", "text", "value"];
  let filteredArray = [];
  if (arrayToFilter) {
    arrayToFilter.forEach((element, keyElem) => {
      filteredArray.push(
        Object.keys(element)
          .filter(key => allowedProps.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: arrayToFilter[keyElem][key]
            };
          }, {})
      );
    });
  }
  return filteredArray;
};

export default filterOutUndesiredProps;

export const fileConstants = {
  PURGE_STATE: 'PURGE_STATE',

  GET_FILES_REQUEST: 'GET_FILES_REQUEST',
  GET_FILES_SUCCESS: 'GET_FILES_SUCCESS',
  GET_FILES_FAILURE: 'GET_FILES_FAILURE',

  SET_LOADING_FILES_SUCCESS: 'SET_LOADING_FILES_SUCCESS',

  COMMIT_FILE_CHANGES_REQUEST: 'COMMIT_FILE_CHANGES_REQUEST',
  COMMIT_FILE_CHANGES_SUCCESS: 'COMMIT_FILE_CHANGES_SUCCESS',
  COMMIT_FILE_CHANGES_FAILURE: 'COMMIT_FILE_CHANGES_FAILURE',

  SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
  SET_UPLOADING: 'SET_UPLOADING',

  DELETE_LOCAL_FILE_SUCCESS: 'DELETE_LOCAL_FILE_SUCCESS',
  ADD_LOCAL_FILE_SUCCESS: 'ADD_LOCAL_FILE_SUCCESS',
  UPDATE_LOCAL_FILES_SUCCESS: 'UPDATE_LOCAL_FILES_SUCCESS'
}

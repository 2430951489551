const getNameFromValues = (input, options) => {
  let result = "";
  if (Array.isArray(input) && options) {
    let match;
    input.forEach((value, index) => {
      match = options.find(field => field.value === value);
      if (match) {
        result += match.text;
        if (index < input.length - 1) {
          result += ", ";
        }
      }
    });
  } else if (input && options) {
    result = options.find(field => field.value === input);
    if (result) {
      return result.text;
    }
  }
  return result;
};

export default {
  getNameFromValues
};

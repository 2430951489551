import axios from "./axios-activities";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createActivity = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error creating activity: ", error.response.data);
          reject(error);
        });
    });
  };

  updateActivity = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating activity: ", error.message);
          reject(error);
        });
    });
  };

  deleteActivity = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting activity: ", error.message);
          reject(error);
        });
    });
  };

  getActivities = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    programmeId,
    referralId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      programmeId: programmeId,
      referralId: referralId
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/list-activities/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting activities: ", error.message);
          reject(error);
        });
    });
  };

  getActivityTypeCount = (groupedTypes, engagementList) => {
    const config = {
      headers: this.headers
    };
    const query = {
      groupedTypes: groupedTypes,
      engagementList: engagementList
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/count-types/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error counting activity types: ", error.message);
          reject(error);
        });
    });
  };

  getActivityById = id => {
    const config = {
      headers: {
        ...this.headers,
        engagement: localStorage.getItem("engagementId"),
        programme: localStorage.getItem("programmeId"),
        referral: localStorage.getItem("parentReferralId")
      }
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting activity: ", error.message);
          reject(error);
        });
    });
  };
  getActivitiesByEngagement = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    engagementId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`/engagements/${engagementId}`, JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting engagement activities: ", error.message);
          reject(error);
        });
    });
  };
}

import React from "react";
import TableComponent from "../../components/CustomerTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { customerActions } from "../../store/actions/customer.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";

const requestTimeout = 20000;

class CustomerListContainer extends React.Component {
  state = {
    deleteModalOpen: false,
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    customerDeletionId: "",
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false,
      globalContactOnly: true
    },
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.customerRequestStatus === undefined &&
        this.props.customerRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearCustomerRequest();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.props.clearCustomerRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }

    sCrit.query = this.state.searchBarQuery;
    sCrit.fields = [
      "firstName.normalizer",
      "surname.normalizer",
      "pNumber.normalizer",
      "scroNumber.normalizer"
    ];

    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      sCrit.activeOnly,
      sCrit.globalContactOnly
    );
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  toggleConfirmDeleteModalVisibility = id => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      customerDeletionId: id
    });
  };

  render = () => {
    const loading = this.props.loadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.customerRequestStatus}
            requestMade={this.props.customerRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.customerRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.customerRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            customerListData={this.props.customerListData}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleRefresh={this.handleRefresh}
            handleModalConfirm={() => {}}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </div>
    );
  };
}

CustomerListContainer.propTypes = {
  customerListData: PropTypes.array.isRequired,
  customerToDelete: PropTypes.string.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getCustomers: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { customers, auth } = state;
  const { roleId, area } = auth;
  const {
    customerListData,
    loadingPage,
    customerToDelete,
    customerRequestStatus,
    customerRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = customers;
  return {
    customerListData,
    loadingPage,
    customerToDelete,
    customerRequestStatus,
    customerRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    area
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomers: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      globalContactOnly
    ) => {
      dispatch(
        customerActions.getCustomers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          globalContactOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      globalContactOnly
    ) => {
      dispatch(
        customerActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          globalContactOnly
        )
      );
    },
    deleteCustomer: (id, headers) => {
      dispatch(customerActions.deleteCustomer(id, headers));
    },
    clearCustomerRequest: () => {
      dispatch(customerActions.clearCustomerRequest());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerListContainer);

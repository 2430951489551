import React, { useState } from "react";
import { Input, Button, Icon } from "semantic-ui-react";
import "./Outputs.scss";
import { strings, enums } from "../../resources";

const Outputs = ({
  output,
  setOutput,
  hasRelatedRecord,
  submitData,
  parseBaseline,
  parseSGA,
  getTypeOfForm
}) => {
  const [stageError, setStageError] = useState(false);

  const handleOutput = (value, index, key) => {
    const newOutput = JSON.parse(JSON.stringify(output));
    newOutput.outputs[index].result[key] = value;
    newOutput.fields[key] = value;
    if (key === "FORM REF") {
      newOutput.formType = getTypeOfForm(value);
    } else if (
      key.replace(/\W/g, "").toLowerCase() ===
      strings.fieldValues.prisonernumber
    )
      newOutput.pNumber = value;
    setOutput(newOutput);
  };

  const downloadFile = data => {
    const json = JSON.stringify(data, null, "\t");
    const docName = data["Name"] || "document";

    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/json;charset=utf-8," + encodeURIComponent(json)
    );
    element.setAttribute("download", `${docName}.json`);
    element.setAttribute("target", "_blank");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleSubmit = item => {
    let requiredObject = JSON.parse(JSON.stringify(enums.forms[item.formType]));
    let submittedObject = JSON.parse(JSON.stringify(item.fields));
    //strip certain characters to make them easier to match
    replaceValues(requiredObject);
    replaceKeys(submittedObject);
    let submissionObject = {};
    submissionObject.pNumber = submittedObject.prisonernumber;
    Object.entries(requiredObject).forEach(values => {
      if (Object.values(submittedObject).indexOf([values[0]])) {
        submissionObject[values[0]] = submittedObject[values[1]];
      }
    });
    switch (submittedObject.formref) {
      case enums.BAS_MonTool:
        submissionObject = parseBaseline(submissionObject);
        break;
      case enums.SGA_Tool:
        submissionObject = parseSGA(submissionObject);
        break;
      default:
        break;
    }
    if (submissionObject.stageError) {
      setStageError(true);
    } else {
      setStageError(false);
      submitData(submissionObject, item.pNumber, item.formType);
    }
  };

  const replaceKeys = object => {
    Object.keys(object).forEach(key => {
      var newKey = key.replace(/\s+/g, "").toLowerCase();
      if (key !== newKey) {
        object[newKey] = object[key];
        delete object[key];
      }
    });
  };

  const replaceValues = object => {
    Object.entries(object).forEach(value => {
      var newValue = value[1].replace(/\s+/g, "").toLowerCase();
      if (value[1] !== newValue) {
        object[value[0]] = newValue;
      }
    });
  };

  const formSuccess = message => <h1 className="formSuccess">{message}</h1>;

  const formFailure = message => <h1 className="formFailure">{message}</h1>;

  const outputMessage = (output, stageError, hasRelatedRecord) => {
    let returnComponent;
    if (!output.pNumber) {
      returnComponent = formFailure(strings.scanner.error.pNumberFailure);
    } else if (!output.formType) {
      returnComponent = formFailure(strings.scanner.error.typeFailure);
    } else if (stageError) {
      returnComponent = formFailure(strings.scanner.error.onlyOneColumn);
    } else if (!hasRelatedRecord) {
      returnComponent = formFailure(strings.scanner.error.noRelatedRecord);
    } else {
      returnComponent = formSuccess(
        strings.scanner.typeSuccess + output.formType
      );
    }
    return returnComponent;
  };

  return (
    <div className="outputs">
      {output.outputs.length > 0 &&
        outputMessage(output, stageError, hasRelatedRecord)}
      {output.outputs.map((item, index) => {
        return (
          <div key={index} className="outputs__item">
            <section className="outputs__form-wrapper">
              <div className="outputs__form">
                {item.result &&
                  Object.keys(item.result).map(key => {
                    return (
                      <Input
                        key={key}
                        label={key}
                        fluid
                        className="outputs__input"
                        placeholder={key}
                        value={item.result[key]}
                        onChange={e => handleOutput(e.target.value, index, key)}
                      />
                    );
                  })}
              </div>
            </section>
            <section className="outputs__image-wrapper">
              <img src={item.img} alt="scanned" className="outputs__image" />
            </section>
          </div>
        );
      })}
      {output.fields && Object.keys(output.fields).length ? (
        <>
          <Button
            secondary
            className="outputs__btn"
            onClick={() => downloadFile(output.fields)}
          >
            <Icon name="save" />
            {strings.scanner.json}
          </Button>{" "}
          <Button
            disabled={output.formType && output.pNumber ? false : true}
            className="modifyButton saveButton"
            onClick={() => handleSubmit(output)}
          >
            {strings.button.submit}
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default Outputs;

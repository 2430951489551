import { referralActionTypes } from "../actions/referral.actionTypes";

const initialState = {
  loadingPage: false,
  selectedReferralData: {},
  referralListData: [{}],
  totalResults: 0,
  referralToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  referralRequestStatus: undefined,
  referralRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case referralActionTypes.CREATE_REFERRAL_REQUEST:
      return {
        ...state,
        referralRequestStatus: undefined,
        referralRequestMade: true
      };
    case referralActionTypes.CREATE_REFERRAL_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        referralRequestStatus: true
      };
    case referralActionTypes.CREATE_REFERRAL_FAILURE:
      let details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Status,
        errorDetails: details,
        referralRequestStatus: false,
        selectedReferralData: action.initialDetails ? action.initialDetails : {}
      };

    case referralActionTypes.CLEAR_CREATE_REFERRAL_REQUEST:
      return { ...state };
    case referralActionTypes.CLEAR_CREATE_REFERRAL_SUCCESS:
      return {
        ...state,
        referralRequestMade: false,
        referralRequestStatus: undefined
      };
    case referralActionTypes.CLEAR_CREATE_REFERRAL_FAILURE:
      return { ...state };

    case referralActionTypes.UPDATE_REFERRAL_REQUEST:
      return {
        ...state,
        loadingPage: true,
        referralRequestStatus: undefined,
        referralRequestMade: true
      };
    case referralActionTypes.UPDATE_REFERRAL_SUCCESS:
      action.response.data.Data.formType = undefined;
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        referralRequestStatus: true,
        referralRequestMade: true,
        selectedReferralData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case referralActionTypes.UPDATE_REFERRAL_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Status,
        errorDetails: details,
        referralRequestStatus: false,
        selectedReferralData: action.initialDetails,
        loadingPage: false
      };

    case referralActionTypes.DELETE_REFERRAL_REQUEST:
      return {
        ...state,
        referralRequestStatus: undefined,
        referralRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case referralActionTypes.DELETE_REFERRAL_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        referralRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case referralActionTypes.DELETE_REFERRAL_FAILURE:
      return {
        ...state,
        error: action.error.message,
        referralRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case referralActionTypes.GET_REFERRALS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        deleteSuccessful: false
      };
    case referralActionTypes.GET_REFERRALS_SUCCESS:
      let rawHits = [];
      let newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newReferral = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newReferral;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        referralListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case referralActionTypes.GET_REFERRALS_FAILURE:
      return {
        ...state,
        referralListData: [{}],
        loadingPage: false
      };

    case referralActionTypes.GET_REFERRAL_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        referralRequestStatus: undefined
      };
    case referralActionTypes.GET_REFERRAL_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        selectedReferralData: action.response.Body.Item,
        loadingPage: false,
        referralRequestStatus: true
      };
    case referralActionTypes.GET_REFERRAL_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        referralRequestStatus: false,
        selectedReferralData: {},
        referralRequestMade: true
      };

    case referralActionTypes.UPDATE_SELECTED_REFERRAL_REQUEST:
      return { ...state };
    case referralActionTypes.UPDATE_SELECTED_REFERRAL_SUCCESS:
      return {
        ...state,
        selectedReferralData: {
          ...state.selectedReferralData,
          [action.key]: action.value
        }
      };
    case referralActionTypes.UPDATE_SELECTED_REFERRAL_FAILURE:
      return { ...state };

    case referralActionTypes.CLEAR_SELECTED_REFERRAL_REQUEST:
      return { ...state };
    case referralActionTypes.CLEAR_SELECTED_REFERRAL_SUCCESS:
      return { ...state, selectedReferralData: {} };
    case referralActionTypes.CLEAR_SELECTED_REFERRAL_FAILURE:
      return { ...state };

    case referralActionTypes.CANCEL_SELECTED_REFERRAL_UPDATE_REQUEST:
      return { ...state };
    case referralActionTypes.CANCEL_SELECTED_REFERRAL_UPDATE_SUCCESS:
      return { ...state, selectedReferralData: action.data };
    case referralActionTypes.CANCEL_SELECTED_REFERRAL_UPDATE_FAILURE:
      return { ...state };

    case referralActionTypes.GET_REFERRAL_STATS_REQUEST:
      return { ...state };
    case referralActionTypes.GET_REFERRAL_STATS_SUCCESS:
      return { ...state, refStats: action.response.data.Body };
    case referralActionTypes.GET_REFERRAL_STATS_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

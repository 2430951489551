import uuidv4 from "uuid/v4";
import { initialStagesBody } from "../../../Baselines/resources/Enums";
import { questionsList } from "../../../AssessmentSG/resources/Enums";
import { convertToIso } from "../../../App/helpers/dateHelpers";
import { strings } from "../../resources/";
export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const uniqueFiles = array => {
  // Warning O(n^2)!
  const a = array.slice(0);
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i].path === a[j].path) a.splice(j--, 1);
    }
  }

  return a;
};

export const detect = async img => {
  const image = img.split(",").pop();
  const formData = {
    requests: [
      {
        image: {
          content: image
        },
        features: [
          {
            type: "DOCUMENT_TEXT_DETECTION"
          }
        ],
        imageContext: {
          languageHints: ["en"]
        }
      }
    ]
  };

  const body = JSON.stringify(formData);

  try {
    const response = await fetch(
      `https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCBQFtoEslOlsu1fmd-r2Pkkw-mz6AbpE8`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }
    );

    return response.json();
  } catch (err) {
    console.log("detect image err: ", err);
  }
};

export const parseText = text => {
  const textArr = text.split("\n").reverse(),
    result = {};

  for (let i = 0; i < textArr.length; i++) {
    if (i === textArr.length - 1) {
      break;
    }

    if (!textArr[i].includes(":")) {
      textArr[i + 1] += ` ${textArr[i]}`;
      textArr[i] = null;
    }
  }

  textArr.reverse();

  for (let i = 0; i < textArr.length; i++) {
    if (textArr[i] && textArr[i].includes(":")) {
      const itemArr = textArr[i].split(":");
      result[itemArr[0]] = itemArr[1].trim();
    }
  }

  return result;
};

export const parseSGA = data => {
  let newData = {};
  let stageNo, dateCompleted;
  for (let stage = 1; stage <= 4; stage++) {
    if (data[stage].replace(/\W/g, "").toLowerCase() === "yes") {
      if (stageNo === undefined) {
        stageNo = stage - 1;
      } else {
        newData.stageError = true;
        return newData;
      }
    }
  }
  switch (stageNo) {
    case 0:
      dateCompleted = { initialAssessment: data.dateCompleted };
      break;
    case 1:
      dateCompleted = { preRelease: data.dateCompleted };
      break;
    case 2:
      dateCompleted = { postRelease: data.dateCompleted };
      break;
    case 3:
      dateCompleted = { exit: data.dateCompleted };
      break;
    default:
      break;
  }
  newData.dateCompleted = dateCompleted;
  newData.customerName = data.customerName;
  questionsList.forEach(stage => {
    if (data.hasOwnProperty(stage.name)) {
      let newValue = new Array(5);
      newValue.splice(stageNo, 1, parseInt(data[stage.name]) - 1);
      newData[stage.name] = newValue;
    }
  });
  newData.assessmentId = uuidv4();
  return newData;
};

export const parseBaseline = data => {
  let newData = {};
  let currentStage, stageNo, dateCompleted;
  for (let stage = 1; stage <= 4; stage++) {
    if (data[stage]) {
      if (!currentStage) {
        currentStage = `stage${stage}`;
        stageNo = stage - 1;
        dateCompleted = data[stage];
      } else {
        newData.stageError = true;
        return newData;
      }
    }
  }
  newData.customerName = data.customerName;
  newData.dateCompleted = {
    [currentStage]: { column: stageNo, value: convertToIso(dateCompleted) }
  };

  initialStagesBody.forEach(stage => {
    if (
      data.hasOwnProperty(stage.name) &&
      stage.name !== strings.fieldValues.dateCompleted
    ) {
      newData[stage.name] = {
        [currentStage]: { column: stageNo, value: data[stage.name] }
      };
    }
  });
  newData.assessmentId = uuidv4();
  return newData;
};

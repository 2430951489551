import React from "react";
import {
  Form,
  Segment,
  Dimmer,
  Loader,
  Message,
  Button
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authActions } from "../../store/actions/auth.actions";
import { strings } from "../../resources";
import "./css/login.scss";
import logo from "../../assets/images/logo_dark.png";

class LoginContainer extends React.Component {
  state = {
    username: "",
    password: "",
    error: ""
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isLoggedIn) {
      if (
        nextProps.location &&
        nextProps.location.state &&
        nextProps.location.state.from &&
        nextProps.location.state.from.pathname &&
        !nextProps.logInButtonClicked
      ) {
        return { error: nextProps.error, url: nextProps.location.state.from.pathname }
      } else {
        return { error: nextProps.error, url: "/mywork" }
      }
    } else {
      return { error: nextProps.error, url: "" }
    }
  }

  componentDidUpdate() {
    if (this.state.url) {
      this.props.history.push(this.state.url)
    }
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleSubmit(event);
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    if (!this.validateForm()) {
      this.setState({
        error: strings.error.emailAndPasswordRequired
      });
      return;
    }
    this.setState({
      error: ""
    });
    this.props.signIn(this.state.username, this.state.password);
  };

  render() {
    return (
      <div className="Login">
        <div className="ui middle aligned center aligned grid">
          <div id="LoginColumn" className="column">
            <br />
            <h2 className="ui image header">
              <center>
                <div className="field">
                  <img
                    className="image"
                    src={logo}
                    alt="The Wise Group"
                    width="80%"
                    height="80%"
                  />
                </div>
              </center>
              <br />
              <div className="content">{strings.header.login}</div>
            </h2>
            <Dimmer.Dimmable
              as={Segment}
              blurring
              dimmed={this.props.loggingIn}
            >
              <Dimmer inverted active={this.props.loggingIn}>
                <Loader size="large">Signing in..</Loader>
              </Dimmer>
              <Segment>
                <Form>
                  <Form.Group>
                    <Form.Input
                      onKeyDown={event => {
                        this.handleKeyDown(event);
                      }}
                      name="username"
                      icon="user"
                      iconPosition="left"
                      onChange={this.handleChange}
                      placeholder={strings.form.placeholder.username}
                      required
                      value={this.state.username || ""}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      onKeyDown={event => {
                        this.handleKeyDown(event);
                      }}
                      name="password"
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      onChange={this.handleChange}
                      placeholder={strings.form.placeholder.password}
                      required
                      value={this.state.password || ""}
                      width={16}
                    />
                  </Form.Group>
                </Form>
                <button
                  className="ui fluid large submit button"
                  id="LoginButton"
                  onClick={this.handleSubmit}
                >
                  {strings.button.loginLabel}
                </button>
              </Segment>
              {this.state.error && <Message error>{this.state.error}</Message>}
              <Button
                className="ui fluid large reset button"
                id="PasswordResetButton"
                onClick={event => this.props.history.push("/reset-password")}
              >
                Password Reset
              </Button>
            </Dimmer.Dimmable>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    loggingIn,
    isLoggedIn,
    headers,
    error,
    logInButtonClicked
  } = state.auth;

  return {
    loggingIn,
    isLoggedIn,
    headers,
    error,
    logInButtonClicked
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: (username, password) => {
      dispatch(authActions.signIn(username, password));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginContainer)
);

import axios from "./axios-engagements";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createEngagement = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateEngagement = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating engagement: ", error.message);
          reject(error);
        });
    });
  };

  deleteEngagement = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting engagement: ", error.message);
          reject(error);
        });
    });
  };

  getEngagements = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    programmeId,
    referralId
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      programmeId: programmeId,
      referralId: referralId
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-engagements/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting engagements: ", error.message);
          reject(error);
        });
    });
  };

  getEngagementsByProgramme = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    programmeId,
    type
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      type: type
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`/programmes/${programmeId}`, JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting programme engagements: ", error.message);
          reject(error);
        });
    });
  };

  getEngagementsByReferral = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    referralId,
    type
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      type: type
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`/referrals/${referralId}`, JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting referral engagements: ", error.message);
          reject(error);
        });
    });
  };

  getEngagementById = id => {
    const config = {
      headers: {
        ...this.headers,
        referral: localStorage.getItem("referralId")
          ? localStorage.getItem("referralId")
          : localStorage.getItem("parentReferralId"),
        programme: localStorage.getItem("programmeId")
      }
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting engagement: ", error.message);
          reject(error);
        });
    });
  };
}

import { API } from "../../services";
import { organisationActionTypes } from "./organisation.actionTypes";

const api = new API();

const createOrganisation = (details, headers) => {
  const request = details => {
    return {
      type: organisationActionTypes.CREATE_ORGANISATION_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: organisationActionTypes.CREATE_ORGANISATION_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: organisationActionTypes.CREATE_ORGANISATION_FAILURE, error };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "createOrganisation" });

  return dispatch => {
    dispatch(request(details));

    api.createOrganisation(details).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };
};

const clearOrganisationRequest = () => {
  const request = () => {
    return { type: organisationActionTypes.CLEAR_CREATE_ORGANISATION_REQUEST };
  };

  const success = () => {
    return { type: organisationActionTypes.CLEAR_CREATE_ORGANISATION_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateOrganisation = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return {
      type: organisationActionTypes.UPDATE_ORGANISATION_REQUEST,
      details
    };
  };
  const success = response => {
    return {
      type: organisationActionTypes.UPDATE_ORGANISATION_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = (error, initialDetails) => {
    return {
      type: organisationActionTypes.UPDATE_ORGANISATION_FAILURE,
      error,
      initialDetails
    };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "updateOrganisation" });

  return dispatch => {
    dispatch(request(details));
    api.updateOrganisation(id, details).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteOrganisation = (id, headers) => {
  const request = () => {
    return { type: organisationActionTypes.DELETE_ORGANISATION_REQUEST };
  };
  const success = response => {
    return {
      type: organisationActionTypes.DELETE_ORGANISATION_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: organisationActionTypes.DELETE_ORGANISATION_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteOrganisation" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteOrganisation(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getOrganisations = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly
) => {
  const request = () => {
    return { type: organisationActionTypes.GET_ORGANISATIONS_REQUEST };
  };
  const success = response => {
    return {
      type: organisationActionTypes.GET_ORGANISATIONS_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: organisationActionTypes.GET_ORGANISATIONS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getOrganisations" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve();
      } else {
        api
          .getOrganisations(queryString, size, from, fields, sort, activeOnly)
          .then(
            response => {
              dispatch(success(response));
              resolve(response);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly
) => {
  const request = () => {
    return { type: organisationActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: organisationActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };

  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getOrganisations(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly
      )
    );
  };
};

const getOrganisationById = (id, headers) => {
  const request = () => {
    return { type: organisationActionTypes.GET_ORGANISATION_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: organisationActionTypes.GET_ORGANISATION_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: organisationActionTypes.GET_ORGANISATION_BY_ID_FAILURE,
      error
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getOrganisationById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getOrganisationById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedOrganisation = (key, value) => {
  const request = (key, value) => {
    return {
      type: organisationActionTypes.UPDATE_SELECTED_ORGANISATION_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: organisationActionTypes.UPDATE_SELECTED_ORGANISATION_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return {
      type: organisationActionTypes.UPDATE_SELECTED_ORGANISATION_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const updateSelectedOrganisationContact = contacts => {
  const request = contacts => {
    return {
      type:
        organisationActionTypes.UPDATE_SELECTED_ORGANISATION_CONTACT_REQUEST,
      contacts
    };
  };
  const success = contacts => {
    return {
      type:
        organisationActionTypes.UPDATE_SELECTED_ORGANISATION_CONTACT_SUCCESS,
      contacts
    };
  };
  const failure = () => {
    return {
      type: organisationActionTypes.UPDATE_SELECTED_ORGANISATION_CONTACT_FAILURE
    };
  };

  return dispatch => {
    dispatch(request(contacts));
    if (contacts) {
      dispatch(success(contacts));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedOrganisation = () => {
  const request = () => {
    return {
      type: organisationActionTypes.CLEAR_SELECTED_ORGANISATION_REQUEST
    };
  };
  const success = () => {
    return {
      type: organisationActionTypes.CLEAR_SELECTED_ORGANISATION_SUCCESS
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedOrganisationUpdate = initialOrganisationData => {
  const request = () => {
    return {
      type: organisationActionTypes.CANCEL_SELECTED_ORGANISATION_UPDATE_REQUEST
    };
  };

  const success = data => {
    return {
      type: organisationActionTypes.CANCEL_SELECTED_ORGANISATION_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialOrganisationData));
  };
};

export const organisationActions = {
  createOrganisation,
  updateOrganisation,
  deleteOrganisation,
  getOrganisationById,
  getOrganisations,
  updateSearchCriteria,
  updateSelectedOrganisation,
  updateSelectedOrganisationContact,
  clearSelectedOrganisation,
  clearOrganisationRequest,
  cancelSelectedOrganisationUpdate
};

import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import {
  strings,
  referralSourceOptionsNrs,
  referralSourceOptionsNrne,
  prisonNrsOptions,
  yearsOptions,
  monthsOptions,
  daysOptions,
  prisonNrneOptions,
  outcomeOfThresholdOptions,
  outcomeReasonEligibleOptions,
  yesNoOptions
} from "../../resources";
import { dropdownHelper, convertFromIso } from "../../helpers/index";
import { ShowIfSameArea } from "../../../App/components/";
import {
  ValidatedFormInput,
  ValidatedFormDropdown,
  ValidatedDateInput
} from "../validation";

import "../css/ReferralComponents.scss";

class IdentificationStage extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <ShowIfSameArea area={this.props.area} allowedArea={strings.areas.NRS}>
          <div className="infoHeader">
            {strings.form.header.identificationMeeting}
          </div>
        </ShowIfSameArea>
        <ShowIfSameArea area={this.props.area} allowedArea={strings.areas.NRNE}>
          <div className="infoHeader">
            {strings.form.header.thresholdMeeting}
          </div>
        </ShowIfSameArea>

        <div className="infoWrapper">
          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRS}
          >
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.referralSourceNrs_initial
                }
                viewData={
                  this.props.selectedReferralData.referralSourceNrs_initial
                }
                dropdownLabel={strings.form.label.referralSourceNrs}
                dropdownName="referralSourceNrs_initial"
                dropdownOptions={referralSourceOptionsNrs}
                dropdownPlaceholder={strings.form.placeholder.referralSourceNrs}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.referralSourceNrs_initial
                }
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.prisonNrs_initial}
                viewData={this.props.selectedReferralData.prisonNrs_initial}
                dropdownLabel={strings.form.label.prisonNrs}
                dropdownName="prisonNrs_initial"
                dropdownOptions={prisonNrsOptions}
                dropdownPlaceholder={strings.form.placeholder.prisonNrs}
                formFieldWidth={8}
                handleChange={this.props.handleChangePrisonNrs}
                validationResult={
                  this.props.validationResults.prisonNrs_initial
                }
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.surname_initial}
                inputLabel={strings.form.label.surname}
                inputName="surname_initial"
                inputPlaceholder={strings.form.placeholder.surname}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.surname_initial}
                mode={this.props.mode}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.firstName_initial}
                inputLabel={strings.form.label.firstName}
                inputName="firstName_initial"
                inputPlaceholder={strings.form.placeholder.firstName}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.firstName_initial
                }
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedDateInput
                inputId="dateOfReferral_initial"
                formInvalid={this.props.formInvalid}
                inputLabel={strings.form.label.dateOfReferral}
                inputName="dateOfReferral_initial"
                inputPlaceholder={strings.form.placeholder.dateOfReferral}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  this.props.selectedReferralData.dateOfReferral_initial
                    ? convertFromIso(
                        this.props.selectedReferralData.dateOfReferral_initial
                      )
                    : ""
                }
                iconPosition="left"
                required
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.dateOfReferral_initial
                }
                formFieldWidth={8}
                mode={this.props.mode}
              />
              <ValidatedDateInput
                inputId="overallEDL_initial"
                formInvalid={this.props.formInvalid}
                inputLabel={strings.form.label.overallEDL}
                inputName="overallEDL_initial"
                inputPlaceholder={strings.form.placeholder.overallEDL}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  this.props.selectedReferralData.overallEDL_initial
                    ? convertFromIso(
                        this.props.selectedReferralData.overallEDL_initial
                      )
                    : ""
                }
                iconPosition="left"
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.overallEDL_initial
                }
                formFieldWidth={8}
                required={this.props.area === strings.areas.NRS}
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedDateInput
                inputId="hdcQualificationDate_initial"
                formInvalid={this.props.formInvalid}
                inputLabel={strings.form.label.hdcQualificationDate}
                inputName="hdcQualificationDate_initial"
                inputPlaceholder={strings.form.placeholder.hdcQualificationDate}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  this.props.selectedReferralData.hdcQualificationDate_initial
                    ? convertFromIso(
                        this.props.selectedReferralData
                          .hdcQualificationDate_initial
                      )
                    : ""
                }
                iconPosition="left"
                required={false}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.hdcQualificationDate_initial
                }
                formFieldWidth={8}
                mode={this.props.mode}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.hallName_initial}
                inputLabel={strings.form.label.hallName}
                inputName="hallName_initial"
                inputPlaceholder={strings.form.placeholder.hallName}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.hallName_initial}
                required={false}
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.level_initial}
                inputLabel={strings.form.label.level}
                inputName="level_initial"
                inputPlaceholder={strings.form.placeholder.level}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.level_initial}
                required={false}
                mode={this.props.mode}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.pNumber_initial}
                inputLabel={strings.form.label.pNumber}
                inputName="pNumber_initial"
                inputPlaceholder={strings.form.placeholder.pNumber}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.pNumber_initial}
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.scroNumber_initial}
                inputLabel={strings.form.label.scroNumber}
                inputName="scroNumber_initial"
                inputPlaceholder={strings.form.placeholder.scroNumber}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.scroNumber_initial
                }
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.consent_initial}
                viewData={this.props.selectedReferralData.consent_initial}
                dropdownLabel={strings.form.label.consent}
                dropdownName="consent_initial"
                dropdownOptions={yesNoOptions}
                dropdownPlaceholder={strings.form.placeholder.consent}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.consent_initial}
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.years_initial}
                viewData={this.props.selectedReferralData.years_initial}
                dropdownLabel={strings.form.label.years}
                dropdownName="years_initial"
                dropdownOptions={yearsOptions}
                dropdownPlaceholder={strings.form.placeholder.years}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.years_initial}
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.months_initial}
                viewData={this.props.selectedReferralData.months_initial}
                dropdownLabel={strings.form.label.months}
                dropdownName="months_initial"
                dropdownOptions={monthsOptions}
                dropdownPlaceholder={strings.form.placeholder.months}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.months_initial}
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.days_initial}
                viewData={this.props.selectedReferralData.days_initial}
                dropdownLabel={strings.form.label.days}
                dropdownName="days_initial"
                dropdownOptions={daysOptions}
                dropdownPlaceholder={strings.form.placeholder.days}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.days_initial}
                required={false}
                mode={this.props.mode}
              />
            </Form.Group>
          </ShowIfSameArea>

          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRNE}
          >
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.initials_initial}
                inputLabel={strings.form.label.initials}
                inputName="initials_initial"
                inputPlaceholder={strings.form.placeholder.initials}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.initials_initial}
                mode={this.props.mode}
              />
              <ValidatedDateInput
                inputId="dateOfReferral_initial"
                formInvalid={this.props.formInvalid}
                inputLabel={strings.form.label.dateOfReferral}
                inputName="dateOfReferral_initial"
                inputPlaceholder={strings.form.placeholder.dateOfReferral}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  this.props.selectedReferralData.dateOfReferral_initial
                    ? convertFromIso(
                        this.props.selectedReferralData.dateOfReferral_initial
                      )
                    : ""
                }
                iconPosition="left"
                required
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.dateOfReferral_initial
                }
                formFieldWidth={8}
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.prisonNrne_initial}
                viewData={this.props.selectedReferralData.prisonNrne_initial}
                dropdownLabel={strings.form.label.prisonNrne}
                dropdownName="prisonNrne_initial"
                dropdownOptions={prisonNrneOptions}
                dropdownPlaceholder={strings.form.placeholder.prisonNrne}
                formFieldWidth={8}
                handleChange={this.props.handleChangePrisonNrne}
                validationResult={
                  this.props.validationResults.prisonNrne_initial
                }
                mode={this.props.mode}
              />
              {this.props.selectedReferralData.prisonNrne_initial ===
                strings.fieldValues.other && (
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={
                    this.props.selectedReferralData.prisonNrneOther_initial
                  }
                  inputLabel={strings.form.label.prisonNrneOther}
                  inputName="prisonNrneOther_initial"
                  inputPlaceholder={strings.form.placeholder.prisonNrneOther}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.prisonNrneOther_initial
                  }
                  mode={this.props.mode}
                />
              )}
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.mentor_initial}
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedReferralData.mentor_initial,
                    this.props.mentorsArea
                  ) || ""
                }
                dropdownLabel={strings.form.label.mentor}
                dropdownName="mentor_initial"
                dropdownOptions={this.props.mentorsArea || []}
                dropdownPlaceholder={strings.form.placeholder.mentor}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.mentor_initial}
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.childRisk_initial}
                viewData={this.props.selectedReferralData.childRisk_initial}
                dropdownLabel={strings.form.label.childRisk}
                dropdownName="childRisk_initial"
                dropdownOptions={yesNoOptions}
                dropdownPlaceholder={strings.form.placeholder.childRisk}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.childRisk_initial
                }
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.knownAdultRisk_initial
                }
                viewData={
                  this.props.selectedReferralData.knownAdultRisk_initial
                }
                dropdownLabel={strings.form.label.knownAdultRisk}
                dropdownName="knownAdultRisk_initial"
                dropdownOptions={yesNoOptions}
                dropdownPlaceholder={strings.form.placeholder.knownAdultRisk}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.knownAdultRisk_initial
                }
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.staffRisk_initial}
                viewData={this.props.selectedReferralData.staffRisk_initial}
                dropdownLabel={strings.form.label.staffRisk}
                dropdownName="staffRisk_initial"
                dropdownOptions={yesNoOptions}
                dropdownPlaceholder={strings.form.placeholder.staffRisk}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.staffRisk_initial
                }
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.publicRisk_initial}
                viewData={this.props.selectedReferralData.publicRisk_initial}
                dropdownLabel={strings.form.label.publicRisk}
                dropdownName="publicRisk_initial"
                dropdownOptions={yesNoOptions}
                dropdownPlaceholder={strings.form.placeholder.publicRisk}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.publicRisk_initial
                }
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.needsAnalysis_initial
                }
                inputLabel={strings.form.label.needsAnalysis}
                inputName="needsAnalysis_initial"
                inputPlaceholder={strings.form.placeholder.needsAnalysis}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.needsAnalysis_initial
                }
                mode={this.props.mode}
              />
              <ValidatedDateInput
                inputId="releaseDate_initial"
                formInvalid={this.props.formInvalid}
                inputLabel={strings.form.label.releaseDate}
                inputName="releaseDate_initial"
                inputPlaceholder={strings.form.placeholder.releaseDate}
                inputDateFormat="DD/MM/YYYY"
                formFieldWidth={8}
                inputData={
                  this.props.selectedReferralData.releaseDate_initial
                    ? convertFromIso(
                        this.props.selectedReferralData.releaseDate_initial
                      )
                    : ""
                }
                onKeyPress={e => e.preventDefault()}
                iconPosition="left"
                required
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.releaseDate_initial
                }
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedDateInput
                inputId="hdcQualificationDate_initial"
                formInvalid={this.props.formInvalid}
                inputLabel={strings.form.label.hdcQualificationDate}
                inputName="hdcQualificationDate_initial"
                inputPlaceholder={strings.form.placeholder.hdcQualificationDate}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  this.props.selectedReferralData.hdcQualificationDate_initial
                    ? convertFromIso(
                        this.props.selectedReferralData
                          .hdcQualificationDate_initial
                      )
                    : ""
                }
                iconPosition="left"
                required={false}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.hdcQualificationDate_initial
                }
                formFieldWidth={8}
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.referralSourceNrne_initial
                }
                viewData={
                  this.props.selectedReferralData.referralSourceNrne_initial
                }
                dropdownLabel={strings.form.label.referralSourceNrne}
                dropdownName="referralSourceNrne_initial"
                dropdownOptions={referralSourceOptionsNrne}
                dropdownPlaceholder={
                  strings.form.placeholder.referralSourceNrne
                }
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.referralSourceNrne_initial
                }
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.outcomeOfThreshold_initial
                }
                viewData={
                  this.props.selectedReferralData.outcomeOfThreshold_initial
                }
                dropdownLabel={strings.form.label.outcomeOfThreshold}
                dropdownName="outcomeOfThreshold_initial"
                dropdownOptions={outcomeOfThresholdOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.outcomeOfThreshold
                }
                dropdownDisabled={this.props.mode === "edit"}
                formFieldWidth={8}
                handleChange={this.props.handleChangeOutcomeOfThreshold}
                validationResult={
                  this.props.validationResults.outcomeOfThreshold_initial
                }
                mode={this.props.mode}
              />

              {this.props.selectedReferralData.outcomeOfThreshold_initial ===
                strings.fieldValues.suitable && (
                <ValidatedFormDropdown
                  formInvalid={this.props.formInvalid}
                  inputData={
                    this.props.selectedReferralData.outcomeReason_initial
                  }
                  viewData={
                    this.props.selectedReferralData.outcomeReason_initial
                  }
                  dropdownLabel={strings.form.label.outcomeReason}
                  dropdownName="outcomeReason_initial"
                  dropdownOptions={outcomeReasonEligibleOptions}
                  dropdownPlaceholder={strings.form.placeholder.outcomeReason}
                  dropdownDisabled={this.props.mode === "edit"}
                  formFieldWidth={8}
                  handleChange={this.props.handleChangeOutcomeReason}
                  validationResult={
                    this.props.validationResults.outcomeReason_initial
                  }
                  mode={this.props.mode}
                />
              )}
              {this.props.selectedReferralData.outcomeOfThreshold_initial ===
                strings.fieldValues.unsuitable && (
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={
                    this.props.selectedReferralData.outcomeReason_initial
                  }
                  inputLabel={strings.form.label.outcomeReason}
                  inputName="outcomeReason_initial"
                  inputPlaceholder={strings.form.placeholder.outcomeReasonText}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.outcomeReason_initial
                  }
                  mode={this.props.mode}
                />
              )}
            </Form.Group>
          </ShowIfSameArea>
        </div>
      </div>
    );
  };
}

IdentificationStage.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default IdentificationStage;

import React from "react";
import ReactTable from "react-table";
import { BasicModal, TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              this.props.history.push(
                "/customers/view/" + rowInfo.original.gcrId
              );
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  mapSearchResultsToRows = () => {
    const { customerListData } = this.props;
    let data = [];
    if (customerListData) {
      customerListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);

    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        Header: strings.table.header.firstName,
        accessor: "firstName",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20),
        width: 140
      },
      {
        Header: strings.table.header.surname,
        accessor: "surname",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 140
      },
      {
        Header: strings.table.header.nin,
        accessor: "nin",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 180
      },
      {
        Header: strings.table.header.address,
        accessor: "libAddress1",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 220
      },
      {
        Header: strings.table.header.libPostcode,
        accessor: "libPostcode",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 90
      },
      {
        Header: strings.table.header.phone1,
        accessor: "phone1",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 180
      },
      {
        Header: strings.table.header.email,
        accessor: "email",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      },
      {
        Header: strings.table.header.pNumber,
        accessor: "pNumber",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 170
      },
      {
        Header: strings.table.header.scroNumber,
        accessor: "scroNumber",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 170
      }
    ];

    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.customerList}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          hideCreate={true}
          handleChange={this.props.handleChange}
          searchBarQuery={this.props.searchBarQuery}
          handleSubmit={this.props.handleSubmit}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
          <BasicModal
            confirmMessage={strings.modal.deleteCustomerMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="deleteCustomerModal"
            modalOpen={this.props.deleteModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleDeleteModal}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  deleteModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  customerListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired
};

export default TableComponent;

export const costActionTypes = {
  CREATE_COST_REQUEST: "CREATE_COST_REQUEST",
  CREATE_COST_SUCCESS: "CREATE_COST_SUCCESS",
  CREATE_COST_FAILURE: "CREATE_COST_FAILURE",

  CLEAR_CREATE_COST_REQUEST: "CLEAR_CREATE_COST_REQUEST",
  CLEAR_CREATE_COST_FAILURE: "CLEAR_CREATE_COST_FAILURE",
  CLEAR_CREATE_COST_SUCCESS: "CLEAR_CREATE_COST_SUCCESS",

  DELETE_COST_REQUEST: "DELETE_COST_REQUEST",
  DELETE_COST_SUCCESS: "DELETE_COST_SUCCESS",
  DELETE_COST_FAILURE: "DELETE_COST_FAILURE",

  UPDATE_SELECTED_COST_DATA_REQUEST: "UPDATE_SELECTED_COST_DATA_REQUEST",
  UPDATE_SELECTED_COST_DATA_SUCCESS: "UPDATE_SELECTED_COST_DATA_SUCCESS",
  UPDATE_SELECTED_COST_DATA_FAILURE: "UPDATE_SELECTED_COST_DATA_FAILURE",

  UPDATE_SELECTED_COST_REQUEST: "UPDATE_SELECTED_COST_REQUEST",
  UPDATE_SELECTED_COST_SUCCESS: "UPDATE_SELECTED_COST_SUCCESS",
  UPDATE_SELECTED_COST_FAILURE: "UPDATE_SELECTED_COST_FAILURE",

  CLEAR_SELECTED_COST_REQUEST: "CLEAR_SELECTED_COST_REQUEST",
  CLEAR_SELECTED_COST_SUCCESS: "CLEAR_SELECTED_COST_SUCCESS",
  CLEAR_SELECTED_COST_FAILURE: "CLEAR_SELECTED_COST_FAILURE",

  CANCEL_SELECTED_COST_UPDATE_REQUEST: "CANCEL_SELECTED_COST_UPDATE_REQUEST",
  CANCEL_SELECTED_COST_UPDATE_SUCCESS: "CANCEL_SELECTED_COST_UPDATE_SUCCESS",
  CANCEL_SELECTED_COST_UPDATE_FAILURE: "CANCEL_SELECTED_COST_UPDATE_FAILURE",

  GET_COSTS_REQUEST: "GET_COSTS_REQUEST",
  GET_COSTS_SUCCESS: "GET_COSTS_SUCCESS",
  GET_COSTS_FAILURE: "GET_COSTS_FAILURE",

  GET_COSTS_BY_PROGRAMME_REQUEST: "GET_COSTS_BY_PROGRAMME_REQUEST",
  GET_COSTS_BY_PROGRAMME_SUCCESS: "GET_COSTS_BY_PROGRAMME_SUCCESS",
  GET_COSTS_BY_PROGRAMME_FAILURE: "GET_COSTS_BY_PROGRAMME_FAILURE",

  UPDATE_COST_REQUEST: "UPDATE_COST_REQUEST",
  UPDATE_COST_SUCCESS: "UPDATE_COST_SUCCESS",
  UPDATE_COST_FAILURE: "UPDATE_COST_FAILURE",

  GET_COST_BY_ID_REQUEST: "GET_COST_BY_ID_REQUEST",
  GET_COST_BY_ID_SUCCESS: "GET_COST_BY_ID_SUCCESS",
  GET_COST_BY_ID_FAILURE: "GET_COST_BY_ID_FAILURE",

  GENERATE_COST_ID_REQUEST: "GENERATE_COST_ID_REQUEST",
  GENERATE_COST_ID_SUCCESS: "GENERATE_COST_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};

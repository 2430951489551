import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings, programmeEngagementOptions } from "../../resources";
import { ValidatedFormDropdown } from "../validation";
import "../css/ProgrammeComponents.scss";

const ChooseEngagement = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.chooseEngagement}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.engagementType}
          dropdownLabel={strings.form.label.engagementType}
          dropdownName="engagementType"
          dropdownOptions={programmeEngagementOptions}
          dropdownPlaceholder={strings.form.placeholder.engagementType}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.engagementType}
          required={false}
          mode="edit"
        />
      </Form.Group>
    </div>
  </div>
);

ChooseEngagement.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default ChooseEngagement;

import React from "react";
import { Icon, Accordion, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import "../App.scss";

class AccordionComponent extends React.Component {
  state = {
    activeIndex:
      typeof this.props.defaultOpenAccordion === "number"
        ? this.props.defaultOpenAccordion
        : -1,
    activeSubItemIndex:
      this.props.defaultOpenSubAccordion === "number"
        ? this.props.defaultOpenSubAccordion
        : -1
  };

  handleClick = (e, { index }) => {
    this.setState({
      activeIndex: this.state.activeIndex === index ? -1 : index
    });
  };

  handleSubItemClick = (e, { index }) => {
    this.setState({
      activeSubItemIndex: this.state.activeSubItemIndex === index ? -1 : index
    });
  };

  render = () => {
    const { accordionSections } = this.props;

    return (
      <Accordion fluid className="accordionComponent">
        {accordionSections.map((accordion, key) => {
          const showAdditionalContent =
            (this.state.activeIndex === key && accordion.additionalContent) ||
            !accordion.title;

          return (
            <React.Fragment key={`fragment-${key}`}>
              {accordion.title && (
                <Accordion.Title
                  active={this.state.activeIndex === key}
                  index={key}
                  onClick={this.handleClick}
                  className="accordionHeader"
                >
                  <Icon name="dropdown" />
                  {accordion.title}
                </Accordion.Title>
              )}
              <Accordion.Content
                active={this.state.activeIndex === key || !accordion.title}
              >
                {Array.isArray(accordion.content)
                  ? accordion.content.map((accordionSubSection, subKey) => {
                      const showAdditionalSubContent =
                        this.state.activeSubItemIndex === subKey &&
                        accordionSubSection.additionalContent;

                      return (
                        <React.Fragment key={`sub-fragment-${subKey}`}>
                          {accordionSubSection.title && (
                            <Accordion.Title
                              active={this.state.activeSubItemIndex === subKey}
                              index={subKey}
                              onClick={this.handleSubItemClick}
                            >
                              <Icon name="dropdown" />
                              {accordionSubSection.title}
                            </Accordion.Title>
                          )}
                          {accordionSubSection.content && (
                            <Accordion.Content
                              active={
                                this.state.activeSubItemIndex === subKey ||
                                !accordionSubSection.title
                              }
                            >
                              {accordionSubSection.content}
                            </Accordion.Content>
                          )}
                          {showAdditionalSubContent && (
                            <Segment basic>
                              {accordionSubSection.additionalContent}
                            </Segment>
                          )}
                        </React.Fragment>
                      );
                    })
                  : accordion.content}
              </Accordion.Content>
              {showAdditionalContent && (
                <Segment basic>{accordion.additionalContent}</Segment>
              )}
            </React.Fragment>
          );
        })}
      </Accordion>
    );
  };
}

AccordionComponent.propTypes = {
  accordionSections: PropTypes.array.isRequired,
  defaultOpenAccordion: PropTypes.number,
  defaultOpenSubAccordion: PropTypes.number
};

export default AccordionComponent;

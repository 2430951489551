const methodOptions = [
  { key: 0, text: "Face-to-face", value: "Face-to-face" },
  { key: 1, text: "Phone call", value: "Phone call" },
  { key: 2, text: "Text", value: "Text" },
  { key: 3, text: "E-mail", value: "E-mail" },
  { key: 4, text: "Letter", value: "Letter" }
];

const typeOptions = [
  { key: 0, text: "Referral", value: "Referral" },
  { key: 1, text: "Sign-up", value: "Sign-up" },
  { key: 2, text: "Sign-up (warm)", value: "Sign-up (warm)" },
  { key: 3, text: "Pre-release Prison", value: "Pre-release Prison" },
  { key: 4, text: "Mid-point Prison", value: "Mid-point Prison" },
  { key: 5, text: "Gate Liberation", value: "Gate Liberation" },
  { key: 6, text: "Weekly", value: "Weekly" },
  { key: 7, text: "Monthly", value: "Monthly" },
  { key: 8, text: "Exit", value: "Exit" },
  { key: 9, text: "3rd Party", value: "3rd Party" }
];

const referralMeetingOutcomeOptions = [
  { key: 0, text: "Follow Up", value: "Follow Up" },
  { key: 1, text: "Declined Service", value: "Declined Service" },
  { key: 2, text: "Sign-up Scheduled", value: "Sign-up Scheduled" },
  { key: 3, text: "Unsuitable", value: "Unsuitable" }
];

const signupMeetingOutcomeOptions = [
  { key: 0, text: "Follow Up", value: "Follow Up" },
  { key: 1, text: "Declined to Attend", value: "Declined to Attend" },
  { key: 2, text: "Declined Service", value: "Declined Service" },
  { key: 3, text: "Customer Start", value: "Customer Start" }
];

const signupMeetingOutcomeOptionsPreReferral = [
  { key: 0, text: "Follow Up", value: "Follow Up" },
  { key: 1, text: "Declined to Attend", value: "Declined to Attend" },
  { key: 2, text: "Declined Service", value: "Declined Service" }
];

const isSuccessfulOptions = [
  { key: 0, text: "Yes", value: "Yes" },
  { key: 1, text: "No", value: "No" }
];

const hasActivitiesOptions = [
  { key: 0, text: "Yes", value: "Yes" },
  { key: 1, text: "No", value: "No" }
];

const locationOptions = [
  { key: 0, text: "Prison", value: "Prison" },
  { key: 1, text: "Community", value: "Community" }
];

const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

export {
  methodOptions,
  typeOptions,
  referralMeetingOutcomeOptions,
  signupMeetingOutcomeOptionsPreReferral,
  signupMeetingOutcomeOptions,
  isSuccessfulOptions,
  hasActivitiesOptions,
  locationOptions,
  yesNoOptions
};

export const feedbackActionTypes = {
  CREATE_FEEDBACK_REQUEST: "CREATE_FEEDBACK_REQUEST",
  CREATE_FEEDBACK_SUCCESS: "CREATE_FEEDBACK_SUCCESS",
  CREATE_FEEDBACK_FAILURE: "CREATE_FEEDBACK_FAILURE",

  CLEAR_CREATE_FEEDBACK_REQUEST: "CLEAR_CREATE_FEEDBACK_REQUEST",
  CLEAR_CREATE_FEEDBACK_FAILURE: "CLEAR_CREATE_FEEDBACK_FAILURE",
  CLEAR_CREATE_FEEDBACK_SUCCESS: "CLEAR_CREATE_FEEDBACK_SUCCESS",

  DELETE_FEEDBACK_REQUEST: "DELETE_FEEDBACK_REQUEST",
  DELETE_FEEDBACK_SUCCESS: "DELETE_FEEDBACK_SUCCESS",
  DELETE_FEEDBACK_FAILURE: "DELETE_FEEDBACK_FAILURE",

  UPDATE_SELECTED_FEEDBACK_DATA_REQUEST:
    "UPDATE_SELECTED_FEEDBACK_DATA_REQUEST",
  UPDATE_SELECTED_FEEDBACK_DATA_SUCCESS:
    "UPDATE_SELECTED_FEEDBACK_DATA_SUCCESS",
  UPDATE_SELECTED_FEEDBACK_DATA_FAILURE:
    "UPDATE_SELECTED_FEEDBACK_DATA_FAILURE",

  UPDATE_SELECTED_FEEDBACK_REQUEST: "UPDATE_SELECTED_FEEDBACK_REQUEST",
  UPDATE_SELECTED_FEEDBACK_SUCCESS: "UPDATE_SELECTED_FEEDBACK_SUCCESS",
  UPDATE_SELECTED_FEEDBACK_FAILURE: "UPDATE_SELECTED_FEEDBACK_FAILURE",

  CANCEL_SELECTED_FEEDBACK_UPDATE_REQUEST:
    "CANCEL_SELECTED_FEEDBACK_UPDATE_REQUEST",
  CANCEL_SELECTED_FEEDBACK_UPDATE_SUCCESS:
    "CANCEL_SELECTED_FEEDBACK_UPDATE_SUCCESS",
  CANCEL_SELECTED_FEEDBACK_UPDATE_FAILURE:
    "CANCEL_SELECTED_FEEDBACK_UPDATE_FAILURE",

  GET_FEEDBACK_REQUEST: "GET_FEEDBACK_REQUEST",
  GET_FEEDBACK_SUCCESS: "GET_FEEDBACK_SUCCESS",
  GET_FEEDBACK_FAILURE: "GET_FEEDBACK_FAILURE",

  UPDATE_FEEDBACK_REQUEST: "UPDATE_FEEDBACK_REQUEST",
  UPDATE_FEEDBACK_SUCCESS: "UPDATE_FEEDBACK_SUCCESS",
  UPDATE_FEEDBACK_FAILURE: "UPDATE_FEEDBACK_FAILURE",

  GET_FEEDBACK_BY_ID_REQUEST: "GET_FEEDBACK_BY_ID_REQUEST",
  GET_FEEDBACK_BY_ID_SUCCESS: "GET_FEEDBACK_BY_ID_SUCCESS",
  GET_FEEDBACK_BY_ID_FAILURE: "GET_FEEDBACK_BY_ID_FAILURE",

  GENERATE_FEEDBACK_ID_REQUEST: "GENERATE_FEEDBACK_ID_REQUEST",
  GENERATE_FEEDBACK_ID_SUCCESS: "GENERATE_FEEDBACK_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  CLEAR_SELECTED_FEEDBACK_REQUEST: "CLEAR_SELECTED_FEEDBACK_REQUEST",
  CLEAR_SELECTED_FEEDBACK_SUCCESS: "CLEAR_SELECTED_FEEDBACK_SUCCESS"
};

import axios from "./axios-baselines";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createBaseline = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateBaseline = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating baseline: ", error.message);
          reject(error);
        });
    });
  };

  deleteBaseline = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting baseline: ", error.message);
          reject(error);
        });
    });
  };

  getBaselinesByProgramme = (queryString, size, from, fields, sort, activeOnly, programmeId, type) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      type: type
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`/programmes/${programmeId}`, JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting programme baselines: ", error.message);
          reject(error);
        });
    });
  };

  getBaselineById = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting baseline: ", error.message);
          reject(error);
        });
    });
  };
}

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";

import { ValidatedFormInput } from "../validation";
import "../css/ProgrammeComponents.scss";
import { ShowIfSameArea } from "../../../App/components/";

const GeneralFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.generalHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <Link
          className="referralLink"
          to={"/referrals/view/" + props.selectedProgrammeData.referralId}
        >
          {strings.button.referralLinkText +
            " " +
            props.selectedProgrammeData.referralCrmId}
        </Link>
      </Form.Group>
      <Form.Group>
        <ShowIfSameArea area={props.area} allowedArea={strings.areas.NRS}>
          <ValidatedFormInput
            formInvalid={props.formInvalid}
            inputData={props.selectedProgrammeData.scroNumber}
            inputLabel={strings.form.label.scroNumber}
            inputName="scroNumber"
            inputPlaceholder={strings.form.placeholder.scroNumber}
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.scroNumber}
            mode={props.mode}
          />
        </ShowIfSameArea>
      </Form.Group>
    </div>
  </div>
);
GeneralFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default GeneralFields;

import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings, areaOptions } from "../../resources";

import { ValidatedFormDropdown } from "../validation";
import "../css/ReferralComponents.scss";

class ChooseArea extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.areaHeader}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.area}
              viewData={this.props.selectedReferralData.area}
              dropdownLabel={strings.form.label.area}
              dropdownName="area"
              dropdownOptions={areaOptions}
              dropdownPlaceholder={strings.form.placeholder.area}
              formFieldWidth={8}
              handleChange={this.props.handleAreaChange}
              validationResult={this.props.validationResults.area}
              mode={this.props.mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };
}

ChooseArea.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleAreaChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default ChooseArea;

class SystemHeaders {
    load = () => {
        try {
            const serializedHeaders = localStorage.getItem('state');
            if (null === serializedHeaders) {
                return {};
            }
    
            return JSON.parse(serializedHeaders);
        } catch (error) {
            return {};
        }
    };
    
    store = state => {
        try {
            localStorage.setItem('state', JSON.stringify(state));
        } catch (error) {
            throw new Error('Unable to save system headers to local storage!');
        }
    };

    purge = () => {
        try {
            localStorage.removeItem('state');
        } catch (error) {
            throw new Error('Unable to purge system headers from local storage!');
        }
    };
}

export default new SystemHeaders();
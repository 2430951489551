import axios from "./axios-assessmentSG";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createAssessmentSG = details => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          reject(error);
        });
    });
  };

  updateAssessmentSG = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating AssessmentSG: ", error.message);
          reject(error);
        });
    });
  };

  deleteAssessmentSG = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, null, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting AssessmentSG: ", error.message);
          reject(error);
        });
    });
  };

  getAssessmentSG = (queryString, size, from, fields, sort, activeOnly) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/list-assessmentSG/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting assessmentSG: ", error.message);
          reject(error);
        });
    });
  };

  getAssessmentSGByProgramme = (
    queryString,
    size,
    from,
    fields,
    sort,
    activeOnly,
    programmeId,
    type
  ) => {
    const config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      query_string: queryString,
      fields: fields,
      sort: sort,
      activeOnly: activeOnly,
      type: type
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`/programmes/${programmeId}`, JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting programme assessmentSG: ", error.message);
          reject(error);
        });
    });
  };

  getAssessmentSGById = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting AssessmentSG: ", error.message);
          reject(error);
        });
    });
  };
}

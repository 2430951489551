import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";

import { ValidatedFormDropdown } from "../validation";
import "../css/ReferralComponents.scss";

class SupportServiceFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.supportService}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.supportService}
              viewData={this.props.selectedReferralData.supportService}
              dropdownLabel={strings.form.label.supportService}
              dropdownName="supportService"
              dropdownOptions={this.props.supportServiceOptions}
              dropdownPlaceholder={strings.form.placeholder.supportService}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.supportService}
              mode={this.props.mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };
}

SupportServiceFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object,
  supportServiceOptions: PropTypes.array.isRequired
};

export default SupportServiceFields;

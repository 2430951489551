import React from "react";
import { Form } from "semantic-ui-react";
import { ValidatedDateInput } from "./validation";
import PropTypes from "prop-types";
import { strings } from "../resources";

class DateRangeComponent extends React.Component {
  render = () => {
    return (
      <Form className="start-end-date">
        <ValidatedDateInput
          inputId="startDate"
          formInvalid={false}
          inputLabel={strings.label.startDate}
          inputName="startDate"
          inputPlaceholder={strings.form.placeholder.enterDate}
          inputDateFormat="DD/MM/YYYY"
          inputData={this.props.dateRangeObject.startDate}
          maxDate={this.props.dateRangeObject.endDate}
          iconPosition="left"
          required={false}
          formFieldWidth={16}
          handleChange={this.props.dateRangeObject.handleDateChange}
          validationResult={[]}
        />
        <ValidatedDateInput
          inputId="endDate"
          formInvalid={false}
          inputLabel={strings.label.endDate}
          inputName="endDate"
          inputPlaceholder={strings.form.placeholder.enterDate}
          inputDateFormat="DD/MM/YYYY"
          inputData={this.props.dateRangeObject.endDate}
          minDate={this.props.dateRangeObject.startDate}
          iconPosition="left"
          required={false}
          formFieldWidth={16}
          handleChange={this.props.dateRangeObject.handleDateChange}
          validationResult={[]}
        />
      </Form>
    );
  };
}

DateRangeComponent.propTypes = {
  dateRangeObject: PropTypes.object.isRequired
};

export default DateRangeComponent;

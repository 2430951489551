import { API } from "../../services/API";
import { addressActionTypes } from "./address.actionTypes";

const api = new API();

const findAddress = (value, fieldName) => {
  const request = value => {
    return { type: addressActionTypes.FIND_ADDRESS_REQUEST, value };
  };
  const success = (response, fieldName) => {
    return {
      type: addressActionTypes.FIND_ADDRESS_SUCCESS,
      response,
      fieldName
    };
  };
  const failure = error => {
    return { type: addressActionTypes.FIND_ADDRESS_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(value, fieldName));
    return new Promise((resolve, reject) => {
      api
        .findAddress(value)
        .then(response => {
          dispatch(success(response, fieldName));
          resolve(response, fieldName);
        })
        .catch(error => {
          dispatch(failure(error));
          reject(error);
        });
    });
  };
};

const retrieveAddress = id => {
  const request = () => {
    return { type: addressActionTypes.RETRIEVE_ADDRESS_REQUEST };
  };
  const success = response => {
    return { type: addressActionTypes.RETRIEVE_ADDRESS_SUCCESS, response };
  };
  const failure = error => {
    return { type: addressActionTypes.RETRIEVE_ADDRESS_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());
    return new Promise(function(resolve, reject) {
      api
        .retrieveAddress(id)
        .then(response => {
          let address = response.data.Data;
          let addressLine1,
            addressLine2 = "";

          if (address.line3) {
            addressLine1 = address.line1 + ", " + address.line2;
            addressLine2 += address.line3;
            if (address.line4) {
              addressLine2 += ", " + address.line4;
              if (address.line5) {
                addressLine2 += ", " + address.line5;
              }
            }
          } else {
            addressLine1 = address.line1;
            addressLine2 = address.line2;
          }

          address.line1 = addressLine1;
          address.line2 = addressLine2;

          dispatch(success(address));
          resolve(address);
        })
        .catch(error => {
          dispatch(failure(error));
          reject(error);
        });
    });
  };
};

export const addressActions = {
  findAddress,
  retrieveAddress
};

export const referralActionTypes = {
  CREATE_REFERRAL_REQUEST: "CREATE_REFERRAL_REQUEST",
  CREATE_REFERRAL_SUCCESS: "CREATE_REFERRAL_SUCCESS",
  CREATE_REFERRAL_FAILURE: "CREATE_REFERRAL_FAILURE",

  CLEAR_CREATE_REFERRAL_REQUEST: "CLEAR_CREATE_REFERRAL_REQUEST",
  CLEAR_CREATE_REFERRAL_FAILURE: "CLEAR_CREATE_REFERRAL_FAILURE",
  CLEAR_CREATE_REFERRAL_SUCCESS: "CLEAR_CREATE_REFERRAL_SUCCESS",

  DELETE_REFERRAL_REQUEST: "DELETE_REFERRAL_REQUEST",
  DELETE_REFERRAL_SUCCESS: "DELETE_REFERRAL_SUCCESS",
  DELETE_REFERRAL_FAILURE: "DELETE_REFERRAL_FAILURE",

  UPDATE_SELECTED_REFERRAL_DATA_REQUEST:
    "UPDATE_SELECTED_REFERRAL_DATA_REQUEST",
  UPDATE_SELECTED_REFERRAL_DATA_SUCCESS:
    "UPDATE_SELECTED_REFERRAL_DATA_SUCCESS",
  UPDATE_SELECTED_REFERRAL_DATA_FAILURE:
    "UPDATE_SELECTED_REFERRAL_DATA_FAILURE",

  UPDATE_SELECTED_REFERRAL_REQUEST: "UPDATE_SELECTED_REFERRAL_REQUEST",
  UPDATE_SELECTED_REFERRAL_SUCCESS: "UPDATE_SELECTED_REFERRAL_SUCCESS",
  UPDATE_SELECTED_REFERRAL_FAILURE: "UPDATE_SELECTED_REFERRAL_FAILURE",

  CLEAR_SELECTED_REFERRAL_REQUEST: "CLEAR_SELECTED_REFERRAL_REQUEST",
  CLEAR_SELECTED_REFERRAL_SUCCESS: "CLEAR_SELECTED_REFERRAL_SUCCESS",
  CLEAR_SELECTED_REFERRAL_FAILURE: "CLEAR_SELECTED_REFERRAL_FAILURE",

  CANCEL_SELECTED_REFERRAL_UPDATE_REQUEST:
    "CANCEL_SELECTED_REFERRAL_UPDATE_REQUEST",
  CANCEL_SELECTED_REFERRAL_UPDATE_SUCCESS:
    "CANCEL_SELECTED_REFERRAL_UPDATE_SUCCESS",
  CANCEL_SELECTED_REFERRAL_UPDATE_FAILURE:
    "CANCEL_SELECTED_REFERRAL_UPDATE_FAILURE",

  GET_REFERRALS_REQUEST: "GET_REFERRALS_REQUEST",
  GET_REFERRALS_SUCCESS: "GET_REFERRALS_SUCCESS",
  GET_REFERRALS_FAILURE: "GET_REFERRALS_FAILURE",

  UPDATE_REFERRAL_REQUEST: "UPDATE_REFERRAL_REQUEST",
  UPDATE_REFERRAL_SUCCESS: "UPDATE_REFERRAL_SUCCESS",
  UPDATE_REFERRAL_FAILURE: "UPDATE_REFERRAL_FAILURE",

  GET_REFERRAL_BY_ID_REQUEST: "GET_REFERRAL_BY_ID_REQUEST",
  GET_REFERRAL_BY_ID_SUCCESS: "GET_REFERRAL_BY_ID_SUCCESS",
  GET_REFERRAL_BY_ID_FAILURE: "GET_REFERRAL_BY_ID_FAILURE",

  GENERATE_REFERRAL_ID_REQUEST: "GENERATE_REFERRAL_ID_REQUEST",
  GENERATE_REFERRAL_ID_SUCCESS: "GENERATE_REFERRAL_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  GET_REFERRAL_STATS_REQUEST: "GET_REFERRAL_STATS_REQUEST",
  GET_REFERRAL_STATS_SUCCESS: "GET_REFERRAL_STATS_SUCCESS",
  GET_REFERRAL_STATS_FAILURE: "GET_REFERRAL_STATS_FAILURE"
};

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authActions } from "../../store/actions/auth.actions";
import "./css/login.scss";
import { Redirect } from "react-router-dom";

class LogoutContainer extends React.Component {
  componentDidMount = () => {
    if (this.props.isLoggedIn) {
      this.props.logout();
    }
  };

  render = () => {
    return (
      <Redirect to={{ pathname: "/", state: { from: this.props.location } }} />
    );
  };
}

const mapStateToProps = state => {
  const { isLoggedIn } = state.auth;

  return {
    isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(authActions.signOut());
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LogoutContainer)
);

import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import {
  strings,
  birthGenderOptions,
  disabilityOptions,
  yesNoSensitiveOptions,
  maritalStatusOptions,
  currentGenderOptions,
  religionOptions,
  sexualOrientationOptions,
  ethnicityOptions
} from "../../resources";
import { ValidatedFormInput, ValidatedFormDropdown } from "../validation";
import "../css/ReferralComponents.scss";

class EqualOpportunitiesFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">
          {strings.form.header.EqualOpportunitiesHeader}
        </div>
        <div className="infoWrapper">
          <Form.Group>
            <Form.Checkbox
              label={strings.form.label.hideProtected}
              name="hideProtected"
              onChange={this.props.handleChange}
              checked={this.props.selectedReferralData.hideProtected || false}
              disabled={this.props.mode === "view"}
              width={8}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.birthGender}
              viewData={this.props.selectedReferralData.birthGender}
              dropdownLabel={strings.form.label.birthGender}
              dropdownName="birthGender"
              dropdownOptions={birthGenderOptions}
              dropdownPlaceholder={strings.form.placeholder.birthGender}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.birthGender}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.disability}
              viewData={this.props.selectedReferralData.disability}
              dropdownLabel={strings.form.label.disability}
              dropdownName="disability"
              dropdownOptions={disabilityOptions}
              dropdownPlaceholder={strings.form.placeholder.disability}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.disability}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.maritalStatus}
              viewData={this.props.selectedReferralData.maritalStatus}
              dropdownLabel={strings.form.label.maritalStatus}
              dropdownName="maritalStatus"
              dropdownOptions={maritalStatusOptions}
              dropdownPlaceholder={strings.form.placeholder.maritalStatus}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.maritalStatus}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.maritalStatusOther}
              inputLabel={strings.form.label.maritalStatusOther}
              inputName="maritalStatusOther"
              inputPlaceholder={strings.form.placeholder.maritalStatusOther}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.maritalStatusOther}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.currentGender}
              viewData={this.props.selectedReferralData.currentGender}
              dropdownLabel={strings.form.label.currentGender}
              dropdownName="currentGender"
              dropdownOptions={currentGenderOptions}
              dropdownPlaceholder={strings.form.placeholder.currentGender}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.currentGender}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.currentGenderOther}
              inputLabel={strings.form.label.currentGenderOther}
              inputName="currentGenderOther"
              inputPlaceholder={strings.form.placeholder.currentGenderOther}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.currentGenderOther}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.religion}
              viewData={this.props.selectedReferralData.religion}
              dropdownLabel={strings.form.label.religion}
              dropdownName="religion"
              dropdownOptions={religionOptions}
              dropdownPlaceholder={strings.form.placeholder.religion}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.religion}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.religionOther}
              inputLabel={strings.form.label.religionOther}
              inputName="religionOther"
              inputPlaceholder={strings.form.placeholder.religionOther}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.religionOther}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.sexualOrientation}
              viewData={this.props.selectedReferralData.sexualOrientation}
              dropdownLabel={strings.form.label.sexualOrientation}
              dropdownName="sexualOrientation"
              dropdownOptions={sexualOrientationOptions}
              dropdownPlaceholder={strings.form.placeholder.sexualOrientation}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.sexualOrientation}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>

          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.ethnicity}
              viewData={this.props.selectedReferralData.ethnicity}
              dropdownLabel={strings.form.label.ethnicity}
              dropdownName="ethnicity"
              dropdownOptions={ethnicityOptions}
              dropdownPlaceholder={strings.form.placeholder.ethnicity}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.ethnicity}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.ethnicityOther}
              inputLabel={strings.form.label.ethnicityOther}
              inputName="ethnicityOther"
              inputPlaceholder={strings.form.placeholder.ethnicityOther}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.ethnicityOther}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.isPregnant}
              viewData={this.props.selectedReferralData.isPregnant}
              dropdownLabel={strings.form.label.isPregnant}
              dropdownName="isPregnant"
              dropdownOptions={yesNoSensitiveOptions}
              dropdownPlaceholder={strings.form.placeholder.isPregnant}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.isPregnant}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.isMaternityPeriod}
              viewData={this.props.selectedReferralData.isMaternityPeriod}
              dropdownLabel={strings.form.label.isMaternityPeriod}
              dropdownName="isMaternityPeriod"
              dropdownOptions={yesNoSensitiveOptions}
              dropdownPlaceholder={strings.form.placeholder.isMaternityPeriod}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.isMaternityPeriod}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };
}

EqualOpportunitiesFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default EqualOpportunitiesFields;

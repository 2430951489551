import React from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";

const NotFoundPage = props => (
  <Segment
    basic
    inverted
    style={{
      height: props.isMobile ? 75 : 85,
      padding: "1em 0",
      margin: "0"
    }}
  >
    <p>Page not found</p>
  </Segment>
);

NotFoundPage.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

export default NotFoundPage;

import { engagementActionTypes } from "../actions/engagement.actionTypes";

const initialState = {
  loadingPage: false,
  selectedEngagementData: {},
  engagementListData: [{}],
  totalResults: 0,
  engagementToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  engagementRequestStatus: undefined,
  engagementRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case engagementActionTypes.CREATE_ENGAGEMENT_REQUEST:
      return {
        ...state,
        engagementRequestStatus: undefined,
        engagementRequestMade: true
      };
    case engagementActionTypes.CREATE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        engagementRequestStatus: true
      };
    case engagementActionTypes.CREATE_ENGAGEMENT_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        engagementRequestStatus: false
      };

    case engagementActionTypes.CLEAR_CREATE_ENGAGEMENT_REQUEST:
      return { ...state };
    case engagementActionTypes.CLEAR_CREATE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        engagementRequestMade: false,
        engagementRequestStatus: undefined
      };
    case engagementActionTypes.CLEAR_CREATE_ENGAGEMENT_FAILURE:
      return { ...state };

    case engagementActionTypes.UPDATE_ENGAGEMENT_REQUEST:
      return {
        ...state,
        loadingPage: true,
        engagementRequestStatus: undefined,
        engagementRequestMade: true
      };
    case engagementActionTypes.UPDATE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        engagementRequestStatus: true,
        selectedEngagementData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case engagementActionTypes.UPDATE_ENGAGEMENT_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        engagementRequestStatus: false,
        selectedEngagementData: action.initialDetails,
        errorDetails: details
      };

    case engagementActionTypes.DELETE_ENGAGEMENT_REQUEST:
      return {
        ...state,
        engagementRequestStatus: undefined,
        engagementRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case engagementActionTypes.DELETE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        engagementRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case engagementActionTypes.DELETE_ENGAGEMENT_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        engagementRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case engagementActionTypes.GET_ENGAGEMENTS_REQUEST:
      return { ...state, loadingPage: true, deleteSuccessful: false };
    case engagementActionTypes.GET_ENGAGEMENTS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newEngagement = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newEngagement;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        engagementListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case engagementActionTypes.GET_ENGAGEMENTS_FAILURE:
      return { ...state, engagementListData: [{}], loadingPage: false };

    case engagementActionTypes.GET_ENGAGEMENTS_BY_REFERRAL_REQUEST:
      return { ...state, loadingPage: true };
    case engagementActionTypes.GET_ENGAGEMENTS_BY_REFERRAL_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.engagements) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.engagements.slice();
        rawHits.forEach((result, index) => {
          let newEngagement = Object.assign({}, result);
          rawHits[index] = {};
          rawHits[index]["_source"] = newEngagement;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        engagementListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case engagementActionTypes.GET_ENGAGEMENTS_BY_REFERRAL_FAILURE:
      return { ...state, engagementListData: [{}], loadingPage: false };

    case engagementActionTypes.GET_ENGAGEMENTS_BY_PROGRAMME_REQUEST:
      return { ...state, loadingPage: true };
    case engagementActionTypes.GET_ENGAGEMENTS_BY_PROGRAMME_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.engagements) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.engagements.slice();
        rawHits.forEach((result, index) => {
          let newEngagement = Object.assign({}, result);
          rawHits[index] = {};
          rawHits[index]["_source"] = newEngagement;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        engagementListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case engagementActionTypes.GET_ENGAGEMENT_BY_PROGRAMME_FAILURE:
      return { ...state, engagementListData: [{}], loadingPage: false };

    case engagementActionTypes.GET_ENGAGEMENT_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        engagementRequestStatus: undefined
      };
    case engagementActionTypes.GET_ENGAGEMENT_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        engagementRequestStatus: true,
        selectedEngagementData: action.response.Body.Item,
        loadingPage: false
      };
    case engagementActionTypes.GET_ENGAGEMENT_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedEngagementData: {},
        engagementRequestStatus: false,
        engagementRequestMade: true
      };

    case engagementActionTypes.UPDATE_SELECTED_ENGAGEMENT_REQUEST:
      return { ...state };
    case engagementActionTypes.UPDATE_SELECTED_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        selectedEngagementData: {
          ...state.selectedEngagementData,
          [action.key]: action.value
        }
      };
    case engagementActionTypes.UPDATE_SELECTED_ENGAGEMENT_FAILURE:
      return { ...state };

    case engagementActionTypes.CLEAR_SELECTED_ENGAGEMENT_REQUEST:
      return { ...state };
    case engagementActionTypes.CLEAR_SELECTED_ENGAGEMENT_SUCCESS:
      return { ...state, selectedEngagementData: {} };
    case engagementActionTypes.CLEAR_SELECTED_ENGAGEMENT_FAILURE:
      return { ...state };

    case engagementActionTypes.CANCEL_SELECTED_ENGAGEMENT_UPDATE_REQUEST:
      return { ...state };
    case engagementActionTypes.CANCEL_SELECTED_ENGAGEMENT_UPDATE_SUCCESS:
      return { ...state, selectedEngagementData: action.data };
    case engagementActionTypes.CANCEL_SELECTED_ENGAGEMENT_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

const contractTypes = [
  { key: 0, text: "Temporary", value: "Temporary" },
  { key: 1, text: "Permanent", value: "Permanent" },
  { key: 2, text: "Fixed-Term", value: "Fixed-Term" }
];

const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];

export { contractTypes, yesNoOptions };

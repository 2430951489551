import React from "react";
import PropTypes from "prop-types";
import { Container, Grid } from "semantic-ui-react";
import "./css/footer.scss";
import { version } from "../../../../../package.json";
import { strings } from "../../../resources/Strings";

const Footer = props =>
  props.isLoggedIn && (
    <div className="footerSection">
      <Container className="footer">
        <Grid columns={2} rows={1}>
          <Grid.Row
            className={
              props.sidebarOpened
                ? "footerRowSidebarOpened"
                : "footerRowSidebarClosed"
            }
          >
            <Grid.Column>
              <p className="company">
                &copy; {new Date().getFullYear()} -{" "}
                <a className="companyLink" href={strings.footer.website}>
                  {strings.footer.pulsion}
                </a>
              </p>
            </Grid.Column>
            <Grid.Column>
              <p className="versionNumber">
                {strings.footer.version} {version}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );

Footer.propTypes = {
  isLoggedIn: PropTypes.bool
};

export default Footer;

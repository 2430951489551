export const assessmentSGActionTypes = {
  CREATE_ASSESSMENTSG_REQUEST: "CREATE_ASSESSMENTSG_REQUEST",
  CREATE_ASSESSMENTSG_SUCCESS: "CREATE_ASSESSMENTSG_SUCCESS",
  CREATE_ASSESSMENTSG_FAILURE: "CREATE_ASSESSMENTSG_FAILURE",

  CLEAR_CREATE_ASSESSMENTSG_REQUEST: "CLEAR_CREATE_ASSESSMENTSG_REQUEST",
  CLEAR_CREATE_ASSESSMENTSG_FAILURE: "CLEAR_CREATE_ASSESSMENTSG_FAILURE",
  CLEAR_CREATE_ASSESSMENTSG_SUCCESS: "CLEAR_CREATE_ASSESSMENTSG_SUCCESS",

  DELETE_ASSESSMENTSG_REQUEST: "DELETE_ASSESSMENTSG_REQUEST",
  DELETE_ASSESSMENTSG_SUCCESS: "DELETE_ASSESSMENTSG_SUCCESS",
  DELETE_ASSESSMENTSG_FAILURE: "DELETE_ASSESSMENTSG_FAILURE",

  UPDATE_SELECTED_ASSESSMENTSG_DATA_REQUEST:
    "UPDATE_SELECTED_ASSESSMENTSG_DATA_REQUEST",
  UPDATE_SELECTED_ASSESSMENTSG_DATA_SUCCESS:
    "UPDATE_SELECTED_ASSESSMENTSG_DATA_SUCCESS",
  UPDATE_SELECTED_ASSESSMENTSG_DATA_FAILURE:
    "UPDATE_SELECTED_ASSESSMENTSG_DATA_FAILURE",

  UPDATE_SELECTED_ASSESSMENTSG_REQUEST: "UPDATE_SELECTED_ASSESSMENTSG_REQUEST",
  UPDATE_SELECTED_ASSESSMENTSG_SUCCESS: "UPDATE_SELECTED_ASSESSMENTSG_SUCCESS",
  UPDATE_SELECTED_ASSESSMENTSG_FAILURE: "UPDATE_SELECTED_ASSESSMENTSG_FAILURE",

  CANCEL_SELECTED_ASSESSMENTSG_UPDATE_REQUEST:
    "CANCEL_SELECTED_ASSESSMENTSG_UPDATE_REQUEST",
  CANCEL_SELECTED_ASSESSMENTSG_UPDATE_SUCCESS:
    "CANCEL_SELECTED_ASSESSMENTSG_UPDATE_SUCCESS",
  CANCEL_SELECTED_ASSESSMENTSG_UPDATE_FAILURE:
    "CANCEL_SELECTED_ASSESSMENTSG_UPDATE_FAILURE",

  GET_ASSESSMENTSG_REQUEST: "GET_ASSESSMENTSG_REQUEST",
  GET_ASSESSMENTSG_SUCCESS: "GET_ASSESSMENTSG_SUCCESS",
  GET_ASSESSMENTSG_FAILURE: "GET_ASSESSMENTSG_FAILURE",

  GET_ASSESSMENTSG_BY_PROGRAMME_REQUEST:
    "GET_ASSESSMENTSG_BY_PROGRAMME_REQUEST",
  GET_ASSESSMENTSG_BY_PROGRAMME_SUCCESS:
    "GET_ASSESSMENTSG_BY_PROGRAMME_SUCCESS",
  GET_ASSESSMENTSG_BY_PROGRAMME_FAILURE:
    "GET_ASSESSMENTSG_BY_PROGRAMME_FAILURE",

  UPDATE_ASSESSMENTSG_REQUEST: "UPDATE_ASSESSMENTSG_REQUEST",
  UPDATE_ASSESSMENTSG_SUCCESS: "UPDATE_ASSESSMENTSG_SUCCESS",
  UPDATE_ASSESSMENTSG_FAILURE: "UPDATE_ASSESSMENTSG_FAILURE",

  GET_ASSESSMENTSG_BY_ID_REQUEST: "GET_ASSESSMENTSG_BY_ID_REQUEST",
  GET_ASSESSMENTSG_BY_ID_SUCCESS: "GET_ASSESSMENTSG_BY_ID_SUCCESS",
  GET_ASSESSMENTSG_BY_ID_FAILURE: "GET_ASSESSMENTSG_BY_ID_FAILURE",

  GENERATE_ASSESSMENTSG_ID_REQUEST: "GENERATE_ASSESSMENTSG_ID_REQUEST",
  GENERATE_ASSESSMENTSG_ID_SUCCESS: "GENERATE_ASSESSMENTSG_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  CLEAR_SELECTED_ASSESSMENTSG_REQUEST: "CLEAR_SELECTED_ASSESSMENTSG_REQUEST",
  CLEAR_SELECTED_ASSESSMENTSG_SUCCESS: "CLEAR_SELECTED_ASSESSMENTSG_SUCCESS"
};

import React from "react";
import { Route, Redirect } from "react-router-dom";

/**
 * PrivateRoute component.
 *
 * Route standard props need to be merged with the passed-down
 * child component props, as they aren't included in the render props.
 */

export const PrivateRoute = ({
  component: Component,
  roles,
  ...injectedProps
}) => (
  <>
    {Component !== undefined && Component !== null && Component !== "" && (
      <Route
        {...injectedProps}
        render={props => {
          // check if user is logged in
          if (!injectedProps.isLoggedIn || !injectedProps.roleId) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }

          // check if route is restricted by role
          if (roles && roles.indexOf(injectedProps.roleId) === -1) {
            return <Redirect to={{ pathname: "/" }} />;
          }

          return <Component {...Object.assign({}, props, injectedProps)} />;
        }}
      />
    )}
  </>
);

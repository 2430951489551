export const baselineActionTypes = {
  CREATE_BASELINE_REQUEST: "CREATE_BASELINE_REQUEST",
  CREATE_BASELINE_SUCCESS: "CREATE_BASELINE_SUCCESS",
  CREATE_BASELINE_FAILURE: "CREATE_BASELINE_FAILURE",

  CLEAR_CREATE_BASELINE_REQUEST: "CLEAR_CREATE_BASELINE_REQUEST",
  CLEAR_CREATE_BASELINE_FAILURE: "CLEAR_CREATE_BASELINE_FAILURE",
  CLEAR_CREATE_BASELINE_SUCCESS: "CLEAR_CREATE_BASELINE_SUCCESS",

  DELETE_BASELINE_REQUEST: "DELETE_BASELINE_REQUEST",
  DELETE_BASELINE_SUCCESS: "DELETE_BASELINE_SUCCESS",
  DELETE_BASELINE_FAILURE: "DELETE_BASELINE_FAILURE",

  UPDATE_SELECTED_BASELINE_DATA_REQUEST:
    "UPDATE_SELECTED_BASELINE_DATA_REQUEST",
  UPDATE_SELECTED_BASELINE_DATA_SUCCESS:
    "UPDATE_SELECTED_BASELINE_DATA_SUCCESS",
  UPDATE_SELECTED_BASELINE_DATA_FAILURE:
    "UPDATE_SELECTED_BASELINE_DATA_FAILURE",

  UPDATE_SELECTED_BASELINE_REQUEST: "UPDATE_SELECTED_BASELINE_REQUEST",
  UPDATE_SELECTED_BASELINE_SUCCESS: "UPDATE_SELECTED_BASELINE_SUCCESS",
  UPDATE_SELECTED_BASELINE_FAILURE: "UPDATE_SELECTED_BASELINE_FAILURE",

  CLEAR_SELECTED_BASELINE_REQUEST: "CLEAR_SELECTED_BASELINE_REQUEST",
  CLEAR_SELECTED_BASELINE_SUCCESS: "CLEAR_SELECTED_BASELINE_SUCCESS",
  CLEAR_SELECTED_BASELINE_FAILURE: "CLEAR_SELECTED_BASELINE_FAILURE",

  CANCEL_SELECTED_BASELINE_UPDATE_REQUEST:
    "CANCEL_SELECTED_BASELINE_UPDATE_REQUEST",
  CANCEL_SELECTED_BASELINE_UPDATE_SUCCESS:
    "CANCEL_SELECTED_BASELINE_UPDATE_SUCCESS",
  CANCEL_SELECTED_BASELINE_UPDATE_FAILURE:
    "CANCEL_SELECTED_BASELINE_UPDATE_FAILURE",

  GET_BASELINES_BY_REFERRAL_REQUEST: "GET_BASELINES_BY_REFERRAL_REQUEST",
  GET_BASELINES_BY_REFERRAL_SUCCESS: "GET_BASELINES_BY_REFERRAL_SUCCESS",
  GET_BASELINES_BY_REFERRAL_FAILURE: "GET_BASELINES_BY_REFERRAL_FAILURE",

  GET_BASELINES_BY_PROGRAMME_REQUEST: "GET_BASELINES_BY_PROGRAMME_REQUEST",
  GET_BASELINES_BY_PROGRAMME_SUCCESS: "GET_BASELINES_BY_PROGRAMME_SUCCESS",
  GET_BASELINES_BY_PROGRAMME_FAILURE: "GET_BASELINES_BY_PROGRAMME_FAILURE",

  UPDATE_BASELINE_REQUEST: "UPDATE_BASELINE_REQUEST",
  UPDATE_BASELINE_SUCCESS: "UPDATE_BASELINE_SUCCESS",
  UPDATE_BASELINE_FAILURE: "UPDATE_BASELINE_FAILURE",

  GET_BASELINE_BY_ID_REQUEST: "GET_BASELINE_BY_ID_REQUEST",
  GET_BASELINE_BY_ID_SUCCESS: "GET_BASELINE_BY_ID_SUCCESS",
  GET_BASELINE_BY_ID_FAILURE: "GET_BASELINE_BY_ID_FAILURE",

  GENERATE_BASELINE_ID_REQUEST: "GENERATE_BASELINE_ID_REQUEST",
  GENERATE_BASELINE_ID_SUCCESS: "GENERATE_BASELINE_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};

import React from "react";
import { Form, Message, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { DateInput } from "semantic-ui-calendar-react";
import DisplayValidatedInfo from "./DisplayValidatedInfo";

const ValidatedDateInput = props => {
  return (props.mode && props.mode === "view") || props.disabled ? (
    <DisplayValidatedInfo
      className="formInputs"
      inputData={props.inputData}
      inputLabel={props.inputLabel}
      formFieldWidth={props.formFieldWidth}
      popUp={props.popUp}
      required={props.required === false ? false : true}
      icon="calendar"
      iconPosition="left"
      noDefaultText={props.noDefaultText}
    />
  ) : (
    <Form.Field width={props.formFieldWidth}>
      <DateInput
        className={props.className}
        id={props.inputId}
        label={props.inputLabel}
        name={props.inputName}
        dateFormat={props.inputDateFormat}
        placeholder={props.inputPlaceholder}
        maxDate={props.maxDate}
        minDate={props.minDate}
        value={props.inputData}
        iconPosition="left"
        required={props.required === false ? false : true}
        onChange={props.handleChange}
        disabled={props.disabled}
        onKeyDown={e => e.preventDefault()}
        clearable={!props.disabled}
        clearIcon={<Icon name="remove" color="red" />}
        closable
        animation="none"
      />
      <Message
        className={props.inputClassname}
        attached={"bottom"}
        error
        hidden={props.hideMessage}
        visible={props.formInvalid && (props.validationResult ? true : false)}
        content={
          props.validationResult ? props.validationResult.toString() : ""
        }
      />
    </Form.Field>
  );
};

ValidatedDateInput.propTypes = {
  iconPosition: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  formInvalid: PropTypes.bool,
  inputData: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  inputDateFormat: PropTypes.string,
  validationResult: PropTypes.array,
  mode: PropTypes.string
};

export default React.memo(ValidatedDateInput);

import { appActionTypes } from "../actions/app.actionTypes";

const initialState = {
  clientId: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.SET_CLIENT_REQUEST:
      return { ...state };
    case appActionTypes.SET_CLIENT_SUCCESS:
      return { ...state, clientId: action.payload };

    default:
      return state;
  }
};

export const employmentActionTypes = {
  CREATE_EMPLOYMENT_REQUEST: "CREATE_EMPLOYMENT_REQUEST",
  CREATE_EMPLOYMENT_SUCCESS: "CREATE_EMPLOYMENT_SUCCESS",
  CREATE_EMPLOYMENT_FAILURE: "CREATE_EMPLOYMENT_FAILURE",

  CLEAR_CREATE_EMPLOYMENT_REQUEST: "CLEAR_CREATE_EMPLOYMENT_REQUEST",
  CLEAR_CREATE_EMPLOYMENT_FAILURE: "CLEAR_CREATE_EMPLOYMENT_FAILURE",
  CLEAR_CREATE_EMPLOYMENT_SUCCESS: "CLEAR_CREATE_EMPLOYMENT_SUCCESS",

  DELETE_EMPLOYMENT_REQUEST: "DELETE_EMPLOYMENT_REQUEST",
  DELETE_EMPLOYMENT_SUCCESS: "DELETE_EMPLOYMENT_SUCCESS",
  DELETE_EMPLOYMENT_FAILURE: "DELETE_EMPLOYMENT_FAILURE",

  UPDATE_SELECTED_EMPLOYMENT_DATA_REQUEST:
    "UPDATE_SELECTED_EMPLOYMENT_DATA_REQUEST",
  UPDATE_SELECTED_EMPLOYMENT_DATA_SUCCESS:
    "UPDATE_SELECTED_EMPLOYMENT_DATA_SUCCESS",
  UPDATE_SELECTED_EMPLOYMENT_DATA_FAILURE:
    "UPDATE_SELECTED_EMPLOYMENT_DATA_FAILURE",

  UPDATE_SELECTED_EMPLOYMENT_REQUEST: "UPDATE_SELECTED_EMPLOYMENT_REQUEST",
  UPDATE_SELECTED_EMPLOYMENT_SUCCESS: "UPDATE_SELECTED_EMPLOYMENT_SUCCESS",
  UPDATE_SELECTED_EMPLOYMENT_FAILURE: "UPDATE_SELECTED_EMPLOYMENT_FAILURE",

  CLEAR_SELECTED_EMPLOYMENT_REQUEST: "CLEAR_SELECTED_EMPLOYMENT_REQUEST",
  CLEAR_SELECTED_EMPLOYMENT_SUCCESS: "CLEAR_SELECTED_EMPLOYMENT_SUCCESS",
  CLEAR_SELECTED_EMPLOYMENT_FAILURE: "CLEAR_SELECTED_EMPLOYMENT_FAILURE",

  CANCEL_SELECTED_EMPLOYMENT_UPDATE_REQUEST:
    "CANCEL_SELECTED_EMPLOYMENT_UPDATE_REQUEST",
  CANCEL_SELECTED_EMPLOYMENT_UPDATE_SUCCESS:
    "CANCEL_SELECTED_EMPLOYMENT_UPDATE_SUCCESS",
  CANCEL_SELECTED_EMPLOYMENT_UPDATE_FAILURE:
    "CANCEL_SELECTED_EMPLOYMENT_UPDATE_FAILURE",

  GET_EMPLOYMENTS_REQUEST: "GET_EMPLOYMENTS_REQUEST",
  GET_EMPLOYMENTS_SUCCESS: "GET_EMPLOYMENTS_SUCCESS",
  GET_EMPLOYMENTS_FAILURE: "GET_EMPLOYMENTS_FAILURE",

  GET_EMPLOYMENTS_BY_PROGRAMME_REQUEST: "GET_EMPLOYMENTS_BY_PROGRAMME_REQUEST",
  GET_EMPLOYMENTS_BY_PROGRAMME_SUCCESS: "GET_EMPLOYMENTS_BY_PROGRAMME_SUCCESS",
  GET_EMPLOYMENTS_BY_PROGRAMME_FAILURE: "GET_EMPLOYMENTS_BY_PROGRAMME_FAILURE",

  UPDATE_EMPLOYMENT_REQUEST: "UPDATE_EMPLOYMENT_REQUEST",
  UPDATE_EMPLOYMENT_SUCCESS: "UPDATE_EMPLOYMENT_SUCCESS",
  UPDATE_EMPLOYMENT_FAILURE: "UPDATE_EMPLOYMENT_FAILURE",

  GET_EMPLOYMENT_BY_ID_REQUEST: "GET_EMPLOYMENT_BY_ID_REQUEST",
  GET_EMPLOYMENT_BY_ID_SUCCESS: "GET_EMPLOYMENT_BY_ID_SUCCESS",
  GET_EMPLOYMENT_BY_ID_FAILURE: "GET_EMPLOYMENT_BY_ID_FAILURE",

  GENERATE_EMPLOYMENT_ID_REQUEST: "GENERATE_EMPLOYMENT_ID_REQUEST",
  GENERATE_EMPLOYMENT_ID_SUCCESS: "GENERATE_EMPLOYMENT_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE"
};

import { costActionTypes } from "../actions/cost.actionTypes";

const initialState = {
  loadingPage: false,
  selectedCostData: {},
  costListData: [{}],
  totalResults: 0,
  costToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  costRequestStatus: undefined,
  costRequestMade: false,
  deleteSuccessful: false,
  showNotification: true
};

export default (state = initialState, action) => {
  let rawHits;
  let details;
  let newTotal;
  switch (action.type) {
    case costActionTypes.CREATE_COST_REQUEST:
      return {
        ...state,
        costRequestStatus: undefined,
        costRequestMade: true
      };
    case costActionTypes.CREATE_COST_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        costRequestStatus: true
      };
    case costActionTypes.CREATE_COST_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        costRequestStatus: false
      };

    case costActionTypes.CLEAR_CREATE_COST_REQUEST:
      return { ...state };
    case costActionTypes.CLEAR_CREATE_COST_SUCCESS:
      return {
        ...state,
        costRequestMade: false,
        costRequestStatus: undefined
      };
    case costActionTypes.CLEAR_CREATE_COST_FAILURE:
      return { ...state };

    case costActionTypes.UPDATE_COST_REQUEST:
      return {
        ...state,
        loadingPage: true,
        costRequestStatus: undefined,
        costRequestMade: true
      };
    case costActionTypes.UPDATE_COST_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        costRequestStatus: true,
        selectedCostData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case costActionTypes.UPDATE_COST_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        costRequestStatus: false,
        selectedCostData: action.initialDetails,
        errorDetails: details
      };

    case costActionTypes.DELETE_COST_REQUEST:
      return {
        ...state,
        costRequestStatus: undefined,
        costRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case costActionTypes.DELETE_COST_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        costRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case costActionTypes.DELETE_COST_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        costRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case costActionTypes.GET_COSTS_REQUEST:
      return { ...state, loadingPage: true };
    case costActionTypes.GET_COSTS_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
      } else {
        rawHits = [];
      }
      return {
        ...state,
        costListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case costActionTypes.GET_COSTS_FAILURE:
      return { ...state, costListData: [{}], loadingPage: false };
    case costActionTypes.GET_COSTS_BY_PROGRAMME_REQUEST:
      return { ...state, loadingPage: true };
    case costActionTypes.GET_COSTS_BY_PROGRAMME_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.data.Body.costs) {
        newTotal = action.response.data.Body.total;
        rawHits = action.response.data.Body.costs.slice();
        rawHits.forEach((result, index) => {
          let newCost = Object.assign({}, result);
          rawHits[index] = {};
          rawHits[index]["_source"] = newCost;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        CostListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case costActionTypes.GET_COSTS_BY_PROGRAMME_FAILURE:
      return { ...state, costListData: [{}], loadingPage: false };

    case costActionTypes.GET_COST_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        costRequestStatus: undefined
      };
    case costActionTypes.GET_COST_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        costRequestStatus: true,
        selectedCostData: action.response.Body,
        loadingPage: false
      };
    case costActionTypes.GET_COST_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        selectedCostData: {},
        costRequestStatus: false,
        costRequestMade: true
      };

    case costActionTypes.UPDATE_SELECTED_COST_REQUEST:
      return { ...state };
    case costActionTypes.UPDATE_SELECTED_COST_SUCCESS:
      return {
        ...state,
        selectedCostData: {
          ...state.selectedCostData,
          [action.key]: action.value
        }
      };
    case costActionTypes.UPDATE_SELECTED_COST_FAILURE:
      return { ...state };

    case costActionTypes.CLEAR_SELECTED_COST_REQUEST:
      return { ...state };
    case costActionTypes.CLEAR_SELECTED_COST_SUCCESS:
      return { ...state, selectedCostData: {} };
    case costActionTypes.CLEAR_SELECTED_COST_FAILURE:
      return { ...state };

    case costActionTypes.CANCEL_SELECTED_COST_UPDATE_REQUEST:
      return { ...state };
    case costActionTypes.CANCEL_SELECTED_COST_UPDATE_SUCCESS:
      return { ...state, selectedCostData: action.data };
    case costActionTypes.CANCEL_SELECTED_COST_UPDATE_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

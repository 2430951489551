import axios from "./axios-tasks";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  createTask = details => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .post("", JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error creating task: ", error.response.data);
          reject(error);
        });
    });
  };

  updateTask = (id, details) => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(details), config)
        .then(response => resolve(response))
        .catch(error => {
          console.log("Error updating task: ", error.message);
          reject(error);
        });
    });
  };

  deleteTask = (id, clearReason) => {
    const config = {
      headers: this.headers
    };
    const params = {
      data: { clearReason: clearReason }
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${id}`, params, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error deleting task: ", error.message);
          reject(error);
        });
    });
  };

  getTasks = (
    queryString,
    size,
    from,
    sort,
    activeOnly,
    fields,
    startDate,
    endDate
  ) => {
    let config = {
      headers: this.headers
    };
    const query = {
      size: size,
      from: from,
      sort: sort,
      fields: fields,
      query_string: queryString
    };
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/user/?includeCleared=" +
            activeOnly +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate,
          JSON.stringify(query),
          config
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting tasks: ", error.message);
          reject(error);
        });
    });
  };

  getTaskById = id => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error getting task: ", error.message);
          reject(error);
        });
    });
  };
}

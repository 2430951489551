export const dropDownOptions = [
  {
    key: 1,
    text: "Approved",
    value: "Approved"
  },
  {
    key: 2,
    text: "Rejected",
    value: "Rejected"
  }
];

export const taskActionTypes = {
  CREATE_TASK_REQUEST: "CREATE_TASK_REQUEST",
  CREATE_TASK_FAILURE: "CREATE_TASK_FAILURE",
  CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",

  CLEAR_CREATE_TASK_REQUEST: "CLEAR_CREATE_TASK_REQUEST",
  CLEAR_CREATE_TASK_FAILURE: "CLEAR_CREATE_TASK_FAILURE",
  CLEAR_CREATE_TASK_SUCCESS: "CLEAR_CREATE_TASK_SUCCESS",

  DELETE_TASK_REQUEST: "DELETE_TASK_REQUEST",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE",

  UPDATE_SELECTED_TASK_DATA_REQUEST: "UPDATE_SELECTED_TASK_DATA_REQUEST",
  UPDATE_SELECTED_TASK_DATA_SUCCESS: "UPDATE_SELECTED_TASK_DATA_SUCCESS",
  UPDATE_SELECTED_TASK_DATA_FAILURE: "UPDATE_SELECTED_TASK_DATA_FAILURE",

  UPDATE_SELECTED_TASK_REQUEST: "UPDATE_SELECTED_TASK_REQUEST",
  UPDATE_SELECTED_TASK_SUCCESS: "UPDATE_SELECTED_TASK_SUCCESS",
  UPDATE_SELECTED_TASK_FAILURE: "UPDATE_SELECTED_TASK_FAILURE",

  CLEAR_SELECTED_TASK_REQUEST: "CLEAR_SELECTED_TASK_REQUEST",
  CLEAR_SELECTED_TASK_SUCCESS: "CLEAR_SELECTED_TASK_SUCCESS",
  CLEAR_SELECTED_TASK_FAILURE: "CLEAR_SELECTED_TASK_FAILURE",

  CANCEL_SELECTED_TASK_UPDATE_REQUEST: "CANCEL_SELECTED_TASK_UPDATE_REQUEST",
  CANCEL_SELECTED_TASK_UPDATE_SUCCESS: "CANCEL_SELECTED_TASK_UPDATE_SUCCESS",
  CANCEL_SELECTED_TASK_UPDATE_FAILURE: "CANCEL_SELECTED_TASK_UPDATE_FAILURE",

  GET_TASKS_REQUEST: "GET_TASKS_REQUEST",
  GET_TASKS_SUCCESS: "GET_TASKS_SUCCESS",
  GET_TASKS_FAILURE: "GET_TASKS_FAILURE",

  UPDATE_TASK_REQUEST: "UPDATE_TASK_REQUEST",
  UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
  UPDATE_TASK_FAILURE: "UPDATE_TASK_FAILURE",

  GET_TASK_BY_ID_REQUEST: "GET_TASK_BY_ID_REQUEST",
  GET_TASK_BY_ID_SUCCESS: "GET_TASK_BY_ID_SUCCESS",
  GET_TASK_BY_ID_FAILURE: "GET_TASK_BY_ID_FAILURE",

  GENERATE_TASK_ID_REQUEST: "GENERATE_TASK_ID_REQUEST",
  GENERATE_TASK_ID_SUCCESS: "GENERATE_TASK_ID_SUCCESS",

  UPDATE_SEARCH_CRITERIA_REQUEST: "UPDATE_SEARCH_CRITERIA_REQUEST",
  UPDATE_SEARCH_CRITERIA_SUCCESS: "UPDATE_SEARCH_CRITERIA_SUCCESS",
  UPDATE_SEARCH_CRITERIA_FAILURE: "UPDATE_SEARCH_CRITERIA_FAILURE",

  SET_TASK_DATA_SELECTED: "SET_TASK_DATA_SELECTED",

  CLEAR_CREATE_TASK: "CLEAR_CREATE_TASK"
};

import React, { Fragment } from "react";
import "./css/TasksWidgetComponent.scss";
import { taskTypes, taskTitleMappings } from "../resources/";
import { Accordion, Icon } from "semantic-ui-react";
import { strings } from "../resources/Strings";

const TasksWidget = ({
  taskListDataByDate,
  handleWidgetClicked,
  handleAccordionClick,
  activeIndex
}) => {
  const widgets = taskTypes.map((type, key) => {
    const tasksOfDate = taskListDataByDate[type];
    if (
      tasksOfDate.due.length > 0 ||
      tasksOfDate.dueSoon.length ||
      tasksOfDate.dueVerySoon.length
    )
      return (
        <div className="task-widget" key={key}>
          <a
            className="top"
            onClick={e => handleWidgetClicked(e, type, "all")}
            href="/#"
          >
            {taskTitleMappings[type]}
          </a>
          <div className="bottom">
            <a
              className="stat-box"
              onClick={e => handleWidgetClicked(e, type, "stillToComplete")}
              href="/#"
            >
              <div>
                <div />
                <p>{tasksOfDate.due.length + tasksOfDate.dueSoon.length}</p>
              </div>
              <p>{strings.tasks.labels.stillToComplete}</p>
            </a>
            <a
              className="stat-box"
              onClick={e => handleWidgetClicked(e, type, "due")}
              href="/#"
            >
              <div>
                <div />
                <p>{tasksOfDate.dueVerySoon.length}</p>
              </div>
              <p>{strings.tasks.labels.due}</p>
            </a>
          </div>
        </div>
      );
    return <Fragment key={key}></Fragment>;
  });
  return (
    <Accordion className="margin-bottom">
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleAccordionClick}
        className={`tasks-accordion + ${
          activeIndex === 0 ? "top-border-radius" : "full-border-radius "
        }`}
      >
        <Icon name="dropdown" />
        {activeIndex === 0 ? "Hide Tasks" : "Show Tasks"}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0} className="negative-margin">
        <div className="flex">{widgets}</div>
      </Accordion.Content>
    </Accordion>
  );
};

export default TasksWidget;
